import { alert as notieAlert, confirm as notieConfirm } from 'notie';
import './Dialog.scss';

export class Dialog {
  static alert(options) {
    let validContexts = ['success', 'warning', 'error', 'info', 'neutral'];

    let messageType = validContexts.find( c => c === options.context );

    notieAlert({
      type: messageType,
      time: 999 || options.time,
      text: this.standardLayout(options.message)
    });
  }

  static confirm(options) {
    if(!options.cancelCallback){
      options.cancelCallback = () => {};
    }

    return new Promise((resolve, reject) => {
      notieConfirm({
        text: `<p>${options.message}</p>`,
        submitText: options.submitText || `Ok`,
        cancelText: options.cancelText || `Cancel`,
        position: options.position || 'top'
      }, () => {
        Promise.resolve(options.callback())
          .then(response => resolve(response));
      }, () => {
        Promise.resolve(options.cancelCallback())
          .then(response => reject(response));
      });
    });
  }

  static standardLayout(message) {
    return `
      <div class='flexible horizontal-sm'>
        <div class='grows padding-bottom-xs'>
          ${message}
        </div>
        <div>
          DISMISS
        </div>
      </div>
    `;
  }
}
