import React, { Component } from 'react';
import GroupService from 'services/group';
import ListRow from './ListRow';
import { withRouter } from 'react-router';

class QuestionsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabFilter: null,
      groups: []
    };
  }

  render() {
    return <div className="QuestionsList">
      {this.props.questions.length > 0 ?
        <ul className="nav nav-tabs">
          {this.state.groups.map(g => {
            return <li role="presentation" key={g.id}
              className={ this.state.tabFilter === g.id ? 'active' : null}>
              <a href="javascript:void(0)" onClick={this.setFilter.bind(this, g)}>{g.name}</a>
            </li>;
          })}
        </ul>
        : null
      }
      <div className="list">
        {this.renderHeader()}
        {this.renderBody()}
      </div>
    </div>;
  }

  renderHeader(){
    return <div className="header hidden-xs">
      <div className="row">
        <div className="col-sm-2">
          Group
        </div>
        <div className="col-sm-4">
          Responders
        </div>
        <div className="col-sm-3">
          Created By
        </div>
        <div className="col-sm-2">
          Status
        </div>
        <div className="col-sm-1">
        </div>
      </div>
    </div>;
  }

  renderBody() {
    const filteredQuestions = this.props.questions
      .filter(q => !this.state.tabFilter || q.groupId === this.state.tabFilter);

    if(!this.props.questions || this.props.questions.length === 0){
      return <div className="card">
        <div className="row">
          <div className="col-sm-12">
            <div className="center-text">
              { this.props.noQuestionsText || "No questions have been added yet" }
            </div>
          </div>
        </div>
      </div>;
    }

    if(filteredQuestions.length === 0){
      let group = this.state.groups.find(g => g.id === this.state.tabFilter) || {};
      return <div className="card">
        <div className="row">
          <div className="col-sm-12">
            <div className="center-text">
              No questions have been added yet for {group.name}
            </div>
          </div>
        </div>
      </div>;
    }

    let urlQuestionMatch = /questions\/(\d+)/i;
    let questionId;
    let matched = urlQuestionMatch.exec(this.props.location.pathname);
    if(matched){
      questionId = matched[1];
    }

    return filteredQuestions.map((q, index) => {
      let expanded = false;
      if(questionId && parseInt(questionId) === q.id) {
        expanded = true;
      }

      return <ListRow {...this.props} question={q} key={q.id} expanded={expanded} index={index + 1} />;
    });
  }

  componentDidMount() {
    return GroupService.get().then(groups => {
      this.setState({
        groups: [{id: null, name: 'All'}, ...groups]
      });
    });
  }

  setFilter(group) {
    this.setState({
      tabFilter: group.id
    });
  }
}

export default withRouter(QuestionsList);
