import ServiceBase from './serviceBase';

class GroupService extends ServiceBase {
  displayName = 'Groups';

  get(){
    return super.get('/groups');
  }
}

export default new GroupService();
