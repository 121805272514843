import ServiceBase from './serviceBase';

class CustomerService extends ServiceBase {
  displayName = 'Customers';

  async get() {
    return await super.get('/customers');
  }
}

export default new CustomerService();
