import React, { Component } from 'react';
import DraftService from 'services/draft';
import DraftsList from './List';
import { LoadingSpinner } from '@dart-design/core';

class DraftsIndex extends Component{
  constructor(props){
    super(props);
    this.state = {
      drafts: [],
      isLoading: true
    };
  }

  componentDidMount() {
    DraftService.getAll()
      .then(drafts => {
        this.setState({
          drafts: drafts,
          isLoading: false
        });
      });
  }

  render() {
    return <div>
        <div className="page-header">
          <h1>My Drafts</h1>
        </div>
        { this.state.isLoading ?
          <div><LoadingSpinner /> Loading Your Drafts</div>
          : <DraftsList drafts={this.state.drafts} /> }
      </div>;
  }
}

export default DraftsIndex;
