import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import EmptyState from 'components/EmptyState';
import ListRow from './ListRow';
import RequestService from 'services/request';
import { LoadingSpinner, Card } from '@dart-design/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import ReactPaginate from 'react-paginate';
import { parse as parseQuery, stringify as stringifyQuery} from 'query-string';
import { withRouter } from 'react-router';
import RequestFilters from './Filters';
import IfCondition from 'components/IfCondition';

class Requests extends Component {
  constructor(props) {
    super(props);

    this.requestSearch = React.createRef();
    let query = parseQuery(props.location.search);

    this.state = {
      isLoading: true,
      requests: [],
      search: query.search || '',
      pageLength: 0,
      requestsResultSetCount: 0,
      isLoadingFilters: true,
      isFiltering: false,
      showAllRequests: Boolean(query.showAllRequests),
    };
  }

  componentDidMount() {
    this.getRequests();
  }

  componentDidUpdate(prevProps) {
    if(prevProps.location.search !== this.props.location.search) {
      this.setState({ isFiltering: true });
      this.getRequests();
    }
  }

  getRequests() {
    return RequestService.getAll(parseQuery(this.props.location.search))
      .then(r => {
        return this.setState({
          requests: r.requests,
          pageCount: Math.ceil(r.totalCount / r.pageLength),
          requestsResultSetCount: r.totalCount,
          pageLength: r.pageLength
        });
      })
      .catch(e => console.log(`Failed to load requests: ${e}`))
      .then(() => this.setState({
        isLoading: false,
        isFiltering: false
      }));
  }

  updateSearch(evt){
    this.setState({
      search: evt.target.value
    });
  }

  performSearch(e) {
    this.updateSearchTerm(this.state.search);
    e.preventDefault();
  }

  clearSearch(e){
    this.updateSearchTerm(undefined);
    this.setState({
      search: ''
    });
    e.preventDefault();
  }

  updateSearchTerm(term){
    let parsedQuery = parseQuery(this.props.location.search);
    parsedQuery.search = term;
    delete parsedQuery.pageNumber;
    let updatedQuery = stringifyQuery(parsedQuery);
    this.props.history.push({
      pathname: '',
      search: '?' + updatedQuery
    });
  }

  renderRows() {
    return (this.state.requests.map(request => {
      return <ListRow key={request.id} request={request}></ListRow>;
    }));
  }

  hasQueryString(){
    return this.props.location.search !== '';
  }

  renderBody() {
    if (this.state.isLoading) {
      return <Card className="card"><LoadingSpinner/> Loading...</Card>;
    }

    return <>
      <div className="row search-form">
        <div className="col-md-4 col-sm-12 col-md-offset-8">
          <form onSubmit={this.performSearch.bind(this)}>
            <div className="form-group">
              <label className="control-label"
                style={ { display: 'none' } }
                htmlFor="requestSearch">Search</label>
              <div className="input-group">
                <input
                  ref={this.requestSearch}
                  placeholder="Request #, code, or keyword"
                  className="form-control"
                  value={this.state.search}
                  onChange={this.updateSearch.bind(this)}
                  id="requestSearch"/>
                <div className="input-group-btn">
                  { this.state.search ?
                    // type of button is important here to not call it when
                    // a user hits enter in the input.
                    <button type="button" className="btn btn-default"
                      onClick={this.clearSearch.bind(this)}>
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                    : ''}
                  <button className="btn btn-default">
                    <FontAwesomeIcon icon={faSearch} />
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <RequestFilters />

      <div className="list">
        <ul className="nav nav-tabs">
          <li role="presentation" key="my-requests"
            className={!this.state.showAllRequests ? 'active': null}>
            <a href="javascript:void(0)" onClick={this.setShowAllRequests.bind(this, undefined)}>My Requests</a>
          </li>
          <li role="presentation" key="all-requests"
            className={this.state.showAllRequests ? 'active': null}>
            <a href="javascript:void(0)" onClick={this.setShowAllRequests.bind(this, true)}>All Requests</a>
          </li>
        </ul>
        <div className="header hidden-xs">
          <div className="row">
            <div className="col-sm-1">Req #</div>
            <div className="col-sm-3">Customer & Request Subject</div>
            <div className="col-sm-2">Created By & Date</div>
            <div className="col-sm-2">Due Date & Time</div>
            <div className="col-sm-2">Category & Material</div>
            <div className="col-sm-1">Status</div>
            <div className="col-sm-1">{/* Actions */}</div>
          </div>
        </div>
        <IfCondition condition={this.state.isFiltering}>
          <LoadingSpinner />
        </IfCondition>
        <IfCondition condition={!this.state.isFiltering}>
          <>
            {this.renderRows()}
            {this.renderNoSearchResults()}
          </>
        </IfCondition>
      </div>

      <div className="text-center">
        {this.renderPagingComponent()}
      </div>
    </>;
  }

  renderPagingComponent() {
    if (this.state.isFiltering || this.state.requestsResultSetCount <= this.state.pageLength) {
      return null;
    }

    return (
      <ReactPaginate
        previousLabel={'previous'}
        nextLabel={'next'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={this.state.pageCount}
        marginPagesDisplayed={1}
        pageRangeDisplayed={3}
        onPageChange={this.handlePageClick.bind(this)}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
        pageLinkClassName={'page-link'}
        forcePage={this.getPageNumber() - 1}>
      </ReactPaginate>
    );
  }

  renderNoSearchResults() {
    if(this.state.requests.length === 0){
      return <EmptyState message={`There are no results for your current filter and/or search`} />;
    }
  }

  getPageNumber() {
    return parseInt(parseQuery(this.props.location.search).pageNumber) || 1;
  }

  setPageNumber(pageNumber) {
    this.updateQueryString({ pageNumber: pageNumber });
  }

  handlePageClick(page) {
    this.setPageNumber(page.selected + 1);
    this.setState(
      {
        isFiltering: true
      },
      () => this.getRequests().then(() => {
        window.scrollTo(0,0);
        this.setState({isFiltering: false});
      })
    );
  }

  setShowAllRequests(value) {
    this.updateQueryString({
      showAllRequests: value,
      pageNumber: undefined
    });
    this.setState({
      showAllRequests: value,
      pageNumber: 1
    });
  }

  updateQueryString(queryObj){
    let parsedQuery = parseQuery(this.props.location.search);
    Object.keys(queryObj).forEach(k => parsedQuery[k] = queryObj[k]);
    let updatedQuery = stringifyQuery(parsedQuery);
    if(updatedQuery !== this.props.location.search){
      this.props.history.push({ search: '?' + updatedQuery });
    }
  }

  render() {
    return (
      <div>
        <div className="page-header">
          <div className="pull-right">
            <Link to={`/requests/new`} className="btn btn-default">
              New Request
            </Link>
          </div>
          <h1>Requests</h1>
        </div>
        { this.renderBody() }
      </div>
    );
  }
}

export default withRouter(Requests);
