import React, { Component } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faAngleUp, faAngleDown, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { withRouter } from 'react-router';
import { Label, Button } from '@dart-design/core';
import Adal from 'services/adal';
import { Route } from 'react-router-dom';
import ResponsesForm from 'components/responses/FormHandler';
import ResponseList from 'components/responses/ResponseList';
import FormattedDate from 'components/FormattedDate';
import AttachmentList from 'components/attachments/AttachmentList';
import QuestionService from 'services/question';
import UserDisplayNames from 'components/UserDisplayNames';
import If from 'components/IfCondition';
import UserUtils from 'utils/user';
import DateUtils from 'utils/date';

class QuestionListRow extends Component {
  constructor(props) {
    super(props);

    this.rowRef = React.createRef();

    this.state = {
      currentUser: Adal.getCachedContext().getCachedUser().profile,
      expanded: props.expanded || false,
      responderString: ''
    };
  }

  render() {
    const { question } = this.props;
    let classNames = 'card card-striped question-card';
    let createdByUserName = UserUtils.displayNameFor(question.createdByUser);

    return <div className={classNames} key={question.id}>
      <div className="row" ref={this.rowRef}>
        <div className="col-sm-2">
          <h3 className="print-only">Question {this.props.index}</h3>
          <span className="hidden-sm hidden-md hidden-lg"><b>Group: </b></span>
          <span className="group-name">
            { question.group.name }
          </span>
        </div>
        <div className="col-sm-4 print-hide">
          <span className="hidden-sm hidden-md hidden-lg"><b>Responders: </b></span>
          <UserDisplayNames userEmails={question.responders}/>
        </div>
        <div className="col-sm-3">
          <div>
            <span className="hidden-sm hidden-md hidden-lg"><b>Created By: </b></span>
            <span className="created-by">
              { createdByUserName }
            </span>
          </div>
          { this.props.showDates ?
            <div>
              <span className="hidden-sm hidden-md hidden-lg"><b>Created Date: </b></span>
              <span className="created-date">
                { moment(question.createdAt).format('M/D/YYYY [@] h:mma') }
              </span>
            </div>
            : null
          }
        </div>
        <div className="col-sm-2">
          <span className="hidden-sm hidden-md hidden-lg"><b>Status: </b></span>
          <span className="status">
            { this.statusText() }
          </span>
        </div>
        <div className="col-sm-1 text-right list-actions">
          {this.renderEditButton()}
          {this.renderExpandButton()}
        </div>
      </div>
      {this.renderExpandedView()}
    </div>;
  }

  async componentDidMount() {
    var matcher = new RegExp(`/questions/${this.props.question.id}$`);
    var result = matcher.exec(this.props.location.href);
    if(this.props.expanded && result) {
      let topOfEle = this.rowRef.current
        .getBoundingClientRect().y;
      window.scroll({top: topOfEle, behavior: 'smooth'});
    }
  }

  renderEditButton() {
    let requestId = this.props.match.params.id;
    let editUrl = `/requests/${requestId}/questions/${this.props.question.id}/edit`;

    if(this.state.currentUser.oid !== this.props.question.createdBy ||
      this.props.disabled){
      return null;
    }

    return <Link to={editUrl}>
      <FontAwesomeIcon icon={faPencilAlt}></FontAwesomeIcon>
    </Link>;
  }

  renderExpandButton() {
    let icon = faAngleDown;
    if(this.state.expanded) {
      icon = faAngleUp;
    }

    return <Button type={Button.types.transparent}
      onClick={() => this.setState({ expanded: !this.state.expanded })}>
      <FontAwesomeIcon icon={icon}/>
    </Button>;
  }

  statusText() {
    //filter out draft responses.
    let responses = this.props.question.responses.filter(
      r => r.submittedOn !== '0001-01-01T00:00:00+00:00'
    );

    if(this.props.question.statusId === 1) {
      return <Label context={Label.contexts.warning} text="Draft"/>;
    }

    if(responses.length === 0 ) {
      return 'Pending response';
    }

    let firstResponse = responses.sort((a,b) => {
      if(a.submittedOn < b.submittedOn) {
        return -1;
      }

      if(a.submittedOn > b.submittedOn) {
        return 1;
      }

      return 0;
    })[0];
    let date = moment(firstResponse.submittedOn).format(DateUtils.atFormat);
    return `Response Posted ${date}`;
  }

  renderExpandedView() {
    if(!this.state.expanded && !this.props.expandAll) {
      return null;
    }

    return <div className='row expanded-view'>
      <div className="col-sm-12">
        <div className="card bg-white">
          <div className="row">
            <div className="col-sm-12">
              <p>
                <b>Responders:</b>
                &nbsp;
                <UserDisplayNames userEmails={this.props.question.responders}/>
                <If condition={!!this.props.question.notifyUsers}>
                  <br/>
                  <b>Notify Users:</b>
                  &nbsp;
                  <UserDisplayNames userEmails={this.props.question.notifyUsers}/>
                </If>
              </p>
              <div className="space-stack-md print-only"></div>
              <div className='question-text rich-text-display'
                dangerouslySetInnerHTML={{__html: this.props.question.text}}/>
              <p>
                Products: { this.props.question.questionProductStockCodes.join(', ') }
              </p>
              <div className="space-stack-md"/>
              <AttachmentList
                attachments={this.props.question.attachments}
                getFile={this.getAttachmentFile.bind(this)}
              />
              <div className="space-stack-md"/>
              <FormattedDate
                className="last-edited"
                user={this.props.question.lastUpdatedByUser}
                lastUpdatedAt={this.props.question.lastUpdatedAt}
                hidden={!this.props.question.lastUpdatedByUser}
                style={{fontStyle: 'italic'}}>
              </FormattedDate>
              <div className="space-stack-lg print-only"></div>
            </div>
          </div>
          { this.canAddResponse() ?
            <div className="row print-hide">
              <div className="col-sm-12">
                <div className="pull-right" style={{ display: this.isRespondButtonHidden() ? 'none' : 'inline-block' }}>
                  <Button onClick={this.openCreateResponseForm.bind(this)}>
                    <FontAwesomeIcon icon={faPlusCircle}></FontAwesomeIcon> Add Response
                  </Button>
                </div>
              </div>
            </div>
          : null }
          <hr className="print-hide" />
          <Route
            path={`${this.props.match.path}/questions/:questionid/responses/new`}
            component={props => <ResponsesForm
              {...this.props}
              defaultNotifyUsers={this.props.question.notifyUsers}
              onResponseEvent={this.props.onResponseCreated}/>} />
          <Route
            path={`${this.props.match.path}/questions/:questionid/responses/:responseid/edit`}
            component={props => <ResponsesForm
              {...this.props}
              onResponseEvent={this.props.onResponseUpdated}/>} />

          <ResponseList
            {...this.props}
            onResponseUpdated={this.props.onResponseUpdated}
            onResponseCreated={this.props.onResponseCreated}
            responses={this.props.question.responses}
            disabled={this.props.disabled} />
        </div>
      </div>
    </div>;
  }

  canAddResponse() {
    return !this.props.disabled && this.props.question.statusId !== 1;
  }

  isRespondButtonHidden() {
    let newOrEdit = /.+\/responses\/((new)|([0-9]+\/edit))$/i;
    let isCreatingOrEditingResponse = this.props.history.location.pathname.match(newOrEdit);

    let isDraftRequest = this.props.requestStatus.description === 'Draft';

    return isCreatingOrEditingResponse || isDraftRequest;
  }

  openCreateResponseForm() {
    var requestId = this.props.match.params.id;
    var questionId = this.props.question.id;
    this.props.history.push(`/requests/${requestId}/questions/${questionId}/responses/new`);
  }

  getAttachmentFile(attachmentId) {
    return QuestionService.getAttachmentFile(this.props.question.id, attachmentId);
  }
}

export default withRouter(QuestionListRow);
