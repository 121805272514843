import ServiceBase from './serviceBase';

class MarketChannelService extends ServiceBase {
  displayName = 'Market Channels';

  async get(){
    return await super.get('/marketChannels');
  }
}

export default new MarketChannelService();
