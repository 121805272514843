import React from 'react';
import FormBase from 'components/FormBase';
import { Input, Select, Button, LoadingSpinner } from '@dart-design/core';
import ProductMaterialService from 'services/productMaterial';
import ProductCategoryService from 'services/productCategory';
import { withRouter } from 'react-router';
import ProductService from 'services/product';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Dialog } from 'Dialog';
import If from 'components/IfCondition';
import Display from 'utils/display';

class Product {
  constructor(data){
    data = data || {};
    this.stockCode = data.stockCode || '';
    this.itemCode = data.itemCode || '';
    this.alternateFor = data.alternateFor || '';
    this.replacementFor = data.replacementFor || '';
    this.printDescription = data.printDescription || '';
    this.colors = data.colors || '';
    this.embossedDescription = data.embossedDescription || '';
    this.productCategoryId = data.productCategoryId || '';
    this.assignedProductMaterials = data.assignedProductMaterials || [];
    this.producingPlants = data.producingPlants || '';
    this.annualVolume = data.annualVolume || '';
    this.caseCount = data.caseCount || '';
    this.stacksPerPackage = data.stacksPerPackage || '';
    this.packageCount = data.packageCount || '';
    this.packagesPerCase = data.packagesPerCase || '';
    this.comments = data.comments || '';
  }
}

class ProductsForm extends FormBase {
  constructor(props){
    super(props);
    this.formRef = React.createRef();

    this.state = Object.assign(new Product(), {
      productMaterials: [],
      productCategories: [],
      isLoading: true
    }, this.state);
  }

  render() {
    if(this.props.disabled){
      return <div className="newProduct">
        You cannot edit anything on a completed request.
      </div>;
    }

    return <div className="newProduct">
      <If condition={this.state.isLoading}>
        <div className="row">
          <div className="col-md-12">
            <LoadingSpinner/>
            &nbsp;
            Loading...
          </div>
        </div>
      </If>
      <If condition={!this.state.isLoading}>
        <div>
          <form id="productForm" ref={this.formRef}>
            <div className="row">
              <div className="col-md-6 col-sm-12">
                {this.renderGeneralErrors()}
                <Input label="Stock Code"
                  type={Input.types.text}
                  value={this.state.stockCode}
                  onChange={this.updateField('stockCode')}
                  context={this.errorContext('StockCode')}
                  help={this.errorMessages('StockCode')}
                  placeholder="Enter stock code"/>
                <Input label="Item Code"
                  type={Input.types.text}
                  value={this.state.itemCode}
                  onChange={this.updateField('itemCode')}
                  context={this.errorContext('ItemCode')}
                  help={this.errorMessages('ItemCode')}
                  placeholder="Enter item code"/>
                <Input label="Alternate For"
                  type={Input.types.text}
                  value={this.state.alternateFor}
                  onChange={this.updateField('alternateFor')}
                  context={this.errorContext('AlternateFor')}
                  help={this.errorMessages('AlternateFor')}
                  placeholder="If an alternate for another product, list that product here"/>
                <Input label="Replacement For"
                  type={Input.types.text}
                  value={this.state.replacementFor}
                  onChange={this.updateField('replacementFor')}
                  context={this.errorContext('ReplacementFor')}
                  help={this.errorMessages('ReplacementFor')}
                  placeholder="If an replacement for another product, list that product here"/>
                <Input label="Print"
                  type={Input.types.text}
                  value={this.state.printDescription}
                  onChange={this.updateField('printDescription')}
                  context={this.errorContext('PrintDescription')}
                  help={this.errorMessages('PrintDescription')}
                  placeholder="If printed, enter name or description of print here"/>
                <Input label="Color"
                  type={Input.types.text}
                  value={this.state.colors}
                  onChange={this.updateField('colors')}
                  context={this.errorContext('Colors')}
                  help={this.errorMessages('Colors')}
                  placeholder="If printed, enter name/number of colors here"/>
                <Input label="Emboss"
                  type={Input.types.text}
                  value={this.state.embossedDescription}
                  onChange={this.updateField('embossedDescription')}
                  context={this.errorContext('EmbossedDescription')}
                  help={this.errorMessages('EmbossedDescription')}
                  placeholder="If embossed, enter emboss design name or description here"/>
                <Select label="Product Category"
                  value={this.state.productCategoryId}
                  options={this.state.productCategories}
                  displayKey="name"
                  valueKey="id"
                  onChange={this.updateField('productCategoryId')}
                  context={this.errorContext('ProductCategoryId')}
                  help={this.errorMessages('ProductCategoryId')}
                  placeholder="Select one"
                  searchable
                  isClearable/>
                <Select label="Material Type"
                  defaultValue={this.state.assignedProductMaterials}
                  options={this.state.productMaterials}
                  isOptionSelected={this.materialSelected.bind(this)}
                  displayKey="name"
                  valueKey="id"
                  onChange={this.updateField('assignedProductMaterials')}
                  context={this.errorContext('toProductMaterials')}
                  help={this.errorMessages('toProductMaterials')}
                  placeholder="Select one"
                  searchable
                  multiple/>
                <Input label="Producing Plant(s)"
                  type={Input.types.text}
                  value={this.state.producingPlants}
                  onChange={this.updateField('producingPlants')}
                  context={this.errorContext('ProducingPlants')}
                  help={this.errorMessages('ProducingPlants')}
                  placeholder="Enter plant(s)"/>
                <Input label="Estimated Annual Volume (M)"
                  type={Input.types.text}
                  value={this.state.annualVolume}
                  onChange={this.updateField('annualVolume')}
                  context={this.errorContext('AnnualVolume')}
                  help={this.errorMessages('AnnualVolume')}
                  placeholder="Enter volume"/>
                <Input label="Case Count"
                  type={Input.types.text}
                  value={this.state.caseCount}
                  onChange={this.updateField('caseCount')}
                  context={this.errorContext('caseCount')}
                  help={this.errorMessages('caseCount')}
                  placeholder="Enter count"/>
                <Input label="Packages/Case"
                  type={Input.types.text}
                  value={this.state.packagesPerCase}
                  onChange={this.updateField('packagesPerCase')}
                  context={this.errorContext('packagesPerCase')}
                  help={this.errorMessages('packagesPerCase')}
                  placeholder="Enter packages"/>
                <Input label="Package Count"
                  type={Input.types.text}
                  value={this.state.packageCount}
                  onChange={this.updateField('packageCount')}
                  context={this.errorContext('packageCount')}
                  help={this.errorMessages('packageCount')}
                  placeholder="Enter count"/>
                <Input label="Stacks/Package"
                  type={Input.types.text}
                  value={this.state.stacksPerPackage}
                  onChange={this.updateField('stacksPerPackage')}
                  context={this.errorContext('stacksPerPackage')}
                  help={this.errorMessages('stacksPerPackage')}
                  placeholder="Enter stacks"/>
                <Input label="Comments"
                  type={Input.types.text}
                  value={this.state.comments}
                  onChange={this.updateField('comments')}
                  context={this.errorContext('Comments')}
                  help={this.errorMessages('Comments')}
                  placeholder="Enter additional comments about this product"/>
              </div>
            </div>
          </form>

          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="pull-right">
                <Button onClick={this.cancel.bind(this)}>
                  Cancel
                </Button>
                <Button onClick={this.submitProduct.bind(this)}
                  type={Button.types.primary}>
                  {this.buttonText()}
                </Button>
              </div>
              { this.props.match.params.productid ?
                <Button onClick={this.deleteProduct.bind(this)} type={Button.types.transparent}>
                  <FontAwesomeIcon icon={faTrash}/>
                  &nbsp;
                  Delete Product
                </Button>
                : null
              }
            </div>
          </div>
        </div>
      </If>
    </div>;
  }

  renderGeneralErrors() {
    let style = {
      display: 'none'
    };

    if(this.state.errors[""]){
      style.display = 'block';
    }

    return <div id="productFormErrors" className="alert alert-danger" style={style}>
      {this.state.errors[""]}
    </div>;
  }

  buttonText() {
    if(this.props.match.params.productid) {
      return 'Update';
    }
    return 'Add';
  }

  componentDidMount(){
    let promises = [
      ProductMaterialService.get(),
      ProductCategoryService.get()
    ];

    if(this.props.match.params.productid) {
      promises.push(ProductService.get(this.props.match.params.productid));
    }

    return Promise.all(promises).then( ([materials, categories, product]) => {
      let state = {
        productMaterials: materials,
        productCategories: categories,
        isLoading: false
      };

      if (product){
        product.assignedProductMaterials = product.toProductMaterials.map(ppm => {
          return {
            label: ppm.productMaterial.name,
            value: ppm.productMaterial.id,
          };
        });
        Object.assign(state, new Product(product));
      }

      this.setState(state);

      // scroll to top of form when it gets opened.
      // alternate:
      // this.formRef.current.querySelector('input').focus();
      if(this.formRef.current){
        Display.scrollToRef(this.formRef);
      }
    });
  }

  returnToRequest() {
    let requestId = this.props.match.params.id;
    this.props.history.push(`/requests/${requestId}`);
  }

  cancel() {
    Dialog.confirm({
      message: 'Your changes will be lost. Continue?',
      submitText: 'Yes',
      cancelText: 'No',
      callback: this.returnToRequest.bind(this)
    });
  }

  submitProduct() {
    let productCall = ProductService.create.bind(ProductService);
    if(this.props.match.params.productid) {
      productCall = ProductService.update.bind(ProductService);
    }

    this.setState({isLoading: true});

    return productCall(this.stateToProduct()).then(async r => {
      this.setState({
        errors: {},
        isLoading: false
      });

      if (!r.ok) {
        let errors;
        try {
          errors = (await r.json()).errors;
        }
        catch (e){
          errors = {};
          errors[""] = "There was a problem with your request";
        }

        this.setState({
          errors: errors
        });

        this.scrollToValidationError();
      } else {
        let product = await r.json();

        if(this.props.onProductEvent){
          product.toProductMaterials.forEach(ppm => {
            ppm.productMaterial = this.state.productMaterials.find(pm =>
              pm.id === ppm.productMaterialId
            );
          });
          product.productCategory = this.state.productCategories.find(pc =>
            pc.id === product.productCategoryId
          );

          this.returnToRequest();
          this.props.onProductEvent(product);
        }
      }
    });
  }

  scrollToValidationError(){
    Display.scrollToRef(this.formRef);
  }

  stateToProduct(){
    let requestId = this.props.match.params.id;
    let productId = this.props.match.params.productid;
    return {
      stockCode: this.state.stockCode,
      itemCode: this.state.itemCode,
      alternateFor: this.state.alternateFor,
      replacementFor: this.state.replacementFor,
      printDescription: this.state.printDescription,
      colors: this.state.colors,
      embossedDescription: this.state.embossedDescription,
      productCategoryId: this.state.productCategoryId,
      producingPlants: this.state.producingPlants,
      annualVolume: this.state.annualVolume,
      caseCount: this.state.caseCount,
      stacksPerPackage: this.state.stacksPerPackage,
      packageCount: this.state.packageCount,
      packagesPerCase: this.state.packagesPerCase,
      comments: this.state.comments,
      requestId: requestId,
      id: productId,
      toProductMaterials: this.state.assignedProductMaterials.map(apm => {
        return {
          productId: productId,
          productMaterialId: apm.value
        };
      })
    };
  }

  deleteProduct() {
    return Dialog
      .confirm({
        callback: () => ProductService.delete(this.props.match.params.productid),
        message: 'Deleting this product cannot be undone.',
        submitText: 'Delete'
      })
      .then(request => {
        this.setState({
          errors: {}
        });

        if(request.ok) {
          let requestId = this.props.match.params.id;
          this.props.history.push(`/requests/${requestId}`);

          if(this.props.onProductDeleted) {
            this.props.onProductDeleted(this.props.match.params.productid);
          }
        } else {
          this.setState({
            errors: {"": 'There was an error deleting this product. Please try again or submit a service desk ticket with the current URL.'}
          });
        }
      });
  }

  materialSelected(material, options){
    return options.map(r => r.value).includes(material.value);
  }
}

export default withRouter(ProductsForm);
