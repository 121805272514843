import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Route } from 'react-router-dom';
import { Button } from '@dart-design/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faFileCsv } from '@fortawesome/free-solid-svg-icons';
import ProductsForm from './Form';
import ProductsTable from './ProductsTable';
import PropTypes from 'prop-types';
import CsvStringify from 'csv-stringify';
import { saveAs } from 'file-saver';
import { Dialog } from 'Dialog';
import moment from 'moment';
import If from 'components/IfCondition';
import UserUtils from 'utils/user';

class ProductsIndex extends Component {
  static propTypes = {
    products: PropTypes.array
  };

  static defaultProps = {
    products: []
  };

  render() {
    return <div className="products">
      <div className="pull-right">
        <Button
          onClick={this.downloadCSV.bind(this)}>
          <FontAwesomeIcon icon={faFileCsv} size="sm"/>
          &nbsp;
          Download
        </Button>
        <If condition={!this.hideNewProductButton()}>
          <Button
            onClick={this.openProductForm.bind(this)}>
            <FontAwesomeIcon icon={faPlusCircle} size="sm"/>
            &nbsp;
            Add Product
          </Button>
        </If>
      </div>
      <h2> Products </h2>
      <Route
        path={`${this.props.match.path}/products/:productid/edit`}
        component={props => <ProductsForm
          {...props}
          onProductEvent={this.props.onProductUpdated}
          onProductDeleted={this.props.onProductDeleted}
          productId={this.props.match.params.productId}
          disabled={this.props.disabled}/>} />
      <Route
        path={`${this.props.match.path}/products/new`}
        component={props => <ProductsForm
          {...props}
          onProductEvent={this.props.onProductCreated}
          disabled={this.props.disabled}/>} />
      <div className="space-stack-xl"></div>
      <ProductsTable products={this.props.products}
        disabled={this.props.disabled}/>
    </div>;
  }

  hideNewProductButton() {
    let matcher = /\/products\/.+$/i;

    let isInForm = matcher.test(this.props.history.location.pathname);
    return this.props.disabled || isInForm;
  }

  openProductForm() {
    var requestId = this.props.match.params.id;
    this.props.history.push(`/requests/${requestId}/products/new`);
  }

  downloadCSV() {
    let requestId = this.props.match.params.id;
    let products = this.props.products.map(p => {
      p.productCategoryName = p.productCategory ? p.productCategory.name : null;
      p.productMaterialName = p.productMaterial ? p.productMaterial.name : null;
      return p;
    });
    CsvStringify(products, {
      header: true,
      columns: [
        { key: 'stockCode', header: 'Stock Code' },
        { key: 'itemCode', header: 'Item Code' },
        { key: 'alternateFor', header: 'Alternate For' },
        { key: 'replacementFor', header: 'Replacement For' },
        { key: 'printDescription', header: 'Print' },
        { key: 'colors', header: 'Color' },
        { key: 'embossedDescription', header: 'Emboss' },
        { key: 'productCategoryName', header: 'Product Category' },
        { key: 'productMaterialName', header: 'Product Material Type' },
        { key: 'producingPlants', header: 'Producing Plants' },
        { key: 'annualVolume', header: 'Estimated Annual Volume(M)' },
        { key: 'caseCount', header: 'Case Count' },
        { key: 'packagesPerCase', header: 'Packages/Case' },
        { key: 'packageCount', header: 'Package Count' },
        { key: 'stacksPerPackage', header: 'Stacks/Package' },
        { key: 'comments', header: 'Comments'}
      ]
    }, (err, csvString) => {
      if(err){
        Dialog.alert({
          message: 'There was an error generating the file for this products table. Please submit a service desk ticket and be sure to include the request # or the url.'
        });
      } else {
        let request = this.props.request;
        let requiredByStr = moment(request.requiredBy).format();
        let csvRequestInfo =
          `"Customer Name","${request.customer}"\n` +
          `"Request #","${request.id}"\n` +
          `"Subject","${request.subject}"\n`+
          `"Market Channel","${request.marketChannel.name}"\n` +
          `"Created By","${UserUtils.displayNameFor(request.createdByUser)}"\n`+
          `"Required By","${requiredByStr}"\n`+
          `\n`;
        let csvBlob = new Blob([csvRequestInfo + csvString], {type: 'text/csv'});
        saveAs(csvBlob, `request${requestId}Products.csv`);
      }
    });
  }
}

export default withRouter(ProductsIndex);
