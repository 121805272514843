import React from 'react';
import FormBase from 'components/FormBase';
import { LoadingSpinner, Select, Button } from '@dart-design/core';
import UserService from 'services/user';
import ResponseService from 'services/response';
import Adal from 'services/adal';
import { withRouter } from 'react-router';
import { Dialog } from 'Dialog';
import RTE from 'components/RichTextEditor';
import FileUploadButton from 'components/attachments/FileUploadButton';
import AttachmentList from 'components/attachments/AttachmentList';
import AttachmentService from 'services/attachment';
import UserUtils from 'utils/user';
import Display from 'utils/display';
import DeleteButton from 'components/DeleteButton';

const MinDate = new Date('0001-01-01T00:00:00+00:00');

class ResponsesForm extends FormBase {
  constructor(props) {
    super(props);

    this.formRef = React.createRef();

    this.state = Object.assign({}, {
      users: [],
      to: [],
      cc: [],
      text: '',
      isLoading: true,
      isSubmitting: false,
      submittedOn: MinDate,
      attachments: [],
    }, this.state);
  }

  render() {
    if (!this.shouldRenderForm()) {
      return null;
    }

    if(this.props.disabled){
      return <div className="responses-form" ref={this.formRef}>
        You cannot edit anything on a completed request.
      </div>;
    }

    if(this.state.isLoading) {
      return <div className="responses-form" ref={this.formRef}>
        <LoadingSpinner/>
        &nbsp;
        Loading Response Form
      </div>;
    }

    var isDraft = (this.state.submittedOn - MinDate) === 0;

    return <div className="responses-form space-stack-md" ref={this.formRef}>
      <h3>
        {!!this.props.responseId ?
          'Edit Response' :
          'Create New Response'
        }
      </h3>

      <form id="responseForm" onSubmit={(e)=>{e.preventDefault(); return false;}} >
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <Select label="To"
              options={this.state.users}
              defaultValue={this.state.to}
              isOptionSelected={this.userSelected.bind(this)}
              multiple
              searchable
              onChange={this.updateField('to')}
              context={this.errorContext('to')}
              help={this.errorMessages('to')}
              placeholder="Select one or more users"
              required
              valueKey="email"
              displayKey="displayName"/>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <Select label="Cc"
              options={this.state.users}
              defaultValue={this.state.cc}
              isOptionSelected={this.userSelected.bind(this)}
              multiple
              searchable
              onChange={this.updateField('cc')}
              context={this.errorContext('cc')}
              help={this.errorMessages('cc')}
              placeholder="Select one or more users"
              valueKey="email"
              displayKey="displayName"/>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <RTE value={this.state.text}
              onChange={this.updateField('text')}
              label="Response"
              required
              context={this.errorContext('text')}
              help={this.errorMessages('text')} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <AttachmentList
                isEditing={true}
                attachments={this.state.attachments}
                toggleDeleteAttachment={this.toggleDeleteAttachment.bind(this)}
                getFile={this.getAttachmentFile.bind(this)}>
            </AttachmentList>
          </div>
          <div className="col-md-12">
            <FileUploadButton style={{ marginLeft: '0' }} handleFile={this.attachAttachment.bind(this)} />
          </div>
        </div>
      </form>

      <div className="row">
        <div className="col-md-12">
          {this.renderDeleteButton()}
          <div className="pull-right">
            <Button onClick={this.cancel.bind(this)}
              disabled={this.state.isSubmitting}>
              Cancel
            </Button>
            { isDraft ?
              <Button onClick={this.saveAsDraft.bind(this)}
                disabled={this.state.isSubmitting}>
                { this.state.isSubmitting ?
                  <span><LoadingSpinner/>&nbsp;</span>
                  : null }
                  Save as Draft
              </Button>
              : null }
            <Button onClick={this.postAndNotify.bind(this)}
              type={Button.types.primary}
              disabled={this.state.isSubmitting}>
              { this.state.isSubmitting ?
                <>
                  <LoadingSpinner/>
                  &nbsp;
                </>
                : null }
              { this.primaryButtonText() }
            </Button>
          </div>
        </div>
      </div>
    </div>;
  }

  primaryButtonText() {
    if(this.props.responseId) {
      if(this.state.statusId === 1) {
        return "Update & Post";
      }

      return "Update";
    }

    return "Add Response";
  }

  componentDidMount() {
    let responseId = this.props.responseId;

    if (this.shouldRenderForm()) {
      Display.scrollToRef(this.formRef);
    }

    let promises = [
      UserService.get()
    ];

    if(responseId){
      promises.push(ResponseService.get(responseId));
    }

    return Promise.all(promises)
      .then(async ([users, response]) => {
        let state = {
          users,
          isLoading: false
        };

        if(response) {
          state.createdBy = response.createdBy;
          state.to = UserUtils.mapEmailStringToArray(response.to, users);
          state.cc = UserUtils.mapEmailStringToArray(response.cc, users);

          if(!!response.text) {
            state.text = response.text;
          }

          state.submittedOn = new Date(response.submittedOn);
          state.statusId = response.statusId;
          state.attachments = response.attachments;
        } else {
          let defaultTo = '';
          if(this.props.parentResponseId){
            defaultTo = this.props.response.createdByUser.email;
          } else {
            defaultTo = this.props.question.createdByUser.email;
          }

          state.cc = UserUtils.mapEmailStringToArray(
            this.props.requestNotifyUsers,
            users);
          state.to = UserUtils.mapEmailStringToArray(
            defaultTo,
            users);
        }

        this.setState(state);
      });
  }

  userSelected(user, options){
    return options.map(r => r.value).includes(user.value);
  }

  stateToResponse(statusId){
    let questionId = this.props.questionId;
    let parentResponseId = this.props.parentResponseId;

    if(parentResponseId) {
      questionId = null;
    }

    var submittedOn = undefined; // if null server will error on draft
    if(statusId === 2){
      submittedOn = (new Date()).toISOString();
    }

    return {
      id: this.props.responseId,
      questionId,
      parentResponseId,
      to: this.state.to.map(r => r.value).join(';'),
      cc: this.state.cc.map(r => r.value).join(';'),
      statusId,
      text: this.state.text,
      createdBy: 'some-user',
      submittedOn
    };
  }

  submitResponse(statusId){
    let responseCall = ResponseService.create.bind(ResponseService);
    if(this.props.responseId) {
      responseCall = ResponseService.update.bind(ResponseService);
    }

    this.setState({
      isSubmitting: true
    });

    return responseCall(this.stateToResponse(statusId))
      .then(async q => {
        this.setState({
          errors: {}
        });

        if (!q.ok) {
          let errors;
          try {
            errors = (await q.json()).errors;
          }
          catch (e){
            errors = {};
            errors[""] = "There was a problem with your request";
          }

          this.setState({
            errors: errors,
            isSubmitting: false
          });
        } else {
          let response = await q.json();

          await this.saveAttachments(response.id);

          let adUser = Adal.getCachedContext().getCachedUser().profile;
          response.createdByUser = {
            displayName: adUser.name
          };


          this.returnToRequest();
          if(this.props.onResponseEvent) {
            response.attachments = this.state.attachments.filter(a => !a.isMarkedForDeletion);
            this.props.onResponseEvent(response);
          }
        }
      });
  }

  returnToRequest() {
    let requestId = this.props.match.params.id;
    let path = `/requests/${requestId}`;

    if(this.props.parentRequestId) {
      path = `/requests/${requestId}/questions/${this.props.questionId}/responses/${this.props.parentResponseId}`;
    }

    this.props.history.push(path);
  }

  cancel() {
    Dialog.confirm({
      message: 'Your changes will be lost. Continue?',
      submitText: 'Yes',
      cancelText: 'No',
      callback: this.returnToRequest.bind(this)
    });
  }

  saveAsDraft() {
    this.submitResponse(1);
  }

  postAndNotify() {
    this.submitResponse(2);
  }

  attachAttachment(file) {
    this.setState({ attachments: [...this.state.attachments, file] });
  }

  saveAttachments(responseId) {
    let promises = [Promise.resolve()];

    this.state.attachments.forEach(attachment => {
        if (!attachment.id && !attachment.isMarkedForDeletion) {
          promises.push(AttachmentService.createResponseAttachment(responseId, attachment));
        }

        if (attachment.id && attachment.isMarkedForDeletion) {
          promises.push(AttachmentService.deleteResponseAttachment(responseId, attachment.id));
        }
    });

    return Promise.all(promises);
  }

  toggleDeleteAttachment(attachment) {
    let attachments = this.state.attachments;
    let _attachment = attachments.find((a) => a === attachment);
    _attachment.isMarkedForDeletion = !_attachment.isMarkedForDeletion;

    this.setState({
        attachments: attachments
    });
  }

  getAttachmentFile(attachmentId) {
    return ResponseService.getAttachmentFile(this.props.match.params.id, attachmentId);
  }

  shouldRenderForm() {
    if (!this.props.parentResponseId && parseInt(this.props.match.params.questionid) === this.props.question.id) {
      return true;
    }

    if (!!this.props.parentResponseId && parseInt(this.props.match.params.responseid) === this.props.response.id) {
      return true;
    }

    return false;
  }

  renderDeleteButton() {
    var currentUser = Adal.getCachedContext().getCachedUser().profile;
    if(currentUser.oid === this.state.createdBy){

      return <DeleteButton onDelete={this.deleteResponse.bind(this)}
        isDisabled={this.state.statusId !== 1}
        disabledTooltipText="Posted responses cannot be deleted.">
          Delete Response
      </DeleteButton>;
    }

    return null;
  }

  deleteResponse() {
    return Dialog.confirm({
      callback: () => {
        this.setState({
          isLoading: true
        });
        return ResponseService.delete(this.props.match.params.responseid);
      },
      message: 'Deleting this response cannot be undone.',
      submitText: 'Delete',
    }).then(request => {
      this.setState({
        errors: {}
      });

      if(request.ok){
        let requestId = this.props.match.params.id;
        this.props.history.push(`/requests/${requestId}`);

        if(this.props.onResponseDeleted) {
          this.props.onResponseDeleted(this.props.match.params.responseid);
        }
      } else {
        this.setState({
          isLoading: false,
          errors: {"": 'There was an error deleting this response. Please try again or submit a service desk ticket with the current URL.'},
        });
      }
    });
  }
}

export default withRouter(ResponsesForm);
