import React, { Component } from 'react';
import ExternalHighlighter from 'react-highlighter';

class Highlight extends Component {
  innerHtmlRegex(){
    let search = this.props.search;
    return new RegExp(`${search}(?!([^<])*?>)(?!<script[^>]*?>)(?![^<]*?</script>|$)`, 'gi');
  }

  render() {
    const search = this.props.search || '';

    if(this.props.children) {
      return <ExternalHighlighter search={search}>
        {this.props.children}
      </ExternalHighlighter>;
    }

    if(this.props.innerHtml) {
      let text = this.props.innerHtml;
      const markedString = `<mark class="highlight">${search}</mark>`;

      text = text.replace(this.innerHtmlRegex(), markedString);

      return <span dangerouslySetInnerHTML={{ __html: text }} />;
    }

    console.warn('This element was not passed innnerHtml or children. Why are you using it?');

    return null;
  }
}

export default Highlight;
