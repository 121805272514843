import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LoadingSpinner, Label, Button } from '@dart-design/core';
import RequestService from 'services/request';
import moment from 'moment';
import { Dialog } from 'Dialog';
import Adal from 'services/adal';
import UserUtils from 'utils/user';
import ProductsIndex from 'components/products/Index';
import QuestionIndex from '../questions/Index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import AttachmentList from 'components/attachments/AttachmentList';
import { parse as parseQuery } from 'query-string';
import Highlight from 'utils/highlight';
import ApproveRequestButtons from './ApproveRequestButtons';
import { Link } from 'react-router-dom';
import If from 'components/IfCondition';
import UserDisplayNames from 'components/UserDisplayNames';
import DateUtils from 'utils/date';

class RequestsShow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      request: {
        products: [],
        attachments: []
      },
      isLoadingRequest: true,
      isPosting: false,
      expandAllQuestions: false
    };

    this.productsRef = React.createRef();

    this.currentUser = Adal.getCachedContext().getCachedUser().profile;
  }

  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string
      })
    })
  }

  async componentDidMount(){
    window.addEventListener('beforeprint', this.onBeforePrint.bind(this));
    window.addEventListener('afterprint', this.onAfterPrint.bind(this));

    await this.getRequest();
  }

  async componentDidUpdate(prevProps) {
    if(prevProps.match.params.id !== this.props.match.params.id) {
      await this.getRequest();
    }
  }

  async getRequest(){
    this.setState({
      isLoadingRequest: true
    });

    let request = await RequestService.get(this.props.match.params.id);

    if(request.notifyUsers === null) {
      request.notifyUsers = '';
    }

    //Pre-load users to get them cached for future calls
    await UserUtils.emailsToDisplayNames(request.notifyUsers.split(';'));

    this.setState({
      isLoadingRequest: false,
      request: request
    });
  }

  componentWillUnmount() {
    window.removeEventListener('beforeprint', this.onBeforePrint);
    window.removeEventListener('afterprint', this.onAfterPrint);
  }

  onRequestChanged() {
    this.setState({
      isLoadingRequest: true
    }, this.getRequest.bind(this));

  }

  onProductCreated(product) {
    this.setState({
      request: {
        ...this.state.request,
        products: this.state.request.products.concat(product)
      }
    });
  }

  onProductUpdated(product) {
    let products = [...this.state.request.products];
    let deleteIndex = products
      .findIndex(p => p.id === parseInt(product.id));
    products.splice(deleteIndex, 1, product);
    this.setState({
      request: {
        ...this.state.request,
        products: products
      }
    });
  }

  onProductDeleted(productId){
    let products = [...this.state.request.products];
    let deleteIndex = products.findIndex(p =>
      p.id === parseInt(productId));
    products.splice(deleteIndex, 1);

    let questions = [...this.state.request.questions];
    questions.forEach(q => {
      if (q.questionProducts) {
        let qpIndex = q.questionProducts.findIndex(qp =>
          qp.productId === parseInt(productId));

        if (qpIndex > -1) {
          q.questionProducts.splice(qpIndex, 1);
        }
      }
    });

    this.setState({
      request: {
        ...this.state.request,
        products,
        questions,
      }
    });
  }

  uniqueQuestionUsers(question){
    var notifyUsers = [this.state.request.notifyUsers, question.responders, question.notifyUsers].join(';').split(';');
    return [...new Set(notifyUsers)].join(';');
  }

  onQuestionCreated(question) {
    this.setState({
      request: {
        ...this.state.request,
        notifyUsers: this.uniqueQuestionUsers(question),
        questions: this.state.request.questions.concat(question)
      }
    });
  }

  onQuestionUpdated(question) {
    let questions = [...this.state.request.questions];
    let deleteIndex = questions
      .findIndex(q => q.id === parseInt(question.id));

    question.responses = questions[deleteIndex].responses || [];

    questions.splice(deleteIndex, 1, question);
    this.setState({
      request: {
        ...this.state.request,
        notifyUsers: this.uniqueQuestionUsers(question),
        questions: questions
      }
    });
  }

  onQuestionDeleted(questionId){
    let questions = [...this.state.request.questions];
    let deleteIndex = questions.findIndex(p =>
      p.id === parseInt(questionId));
    questions.splice(deleteIndex, 1);
    this.setState({
      request: {
        ...this.state.request,
        questions: questions
      }
    });
  }

  uniqueResponseUsers(response) {
    var notifyUsers = [this.state.request.notifyUsers, response.to, response.cc].join(';').split(';');
    return [...new Set(notifyUsers)].join(';');
  }

  onResponseCreated(response) {
    if(response.questionId){
      let question = this.state.request.questions
        .find(q => q.id === response.questionId);

      if(!question.responses) {
        question.responses = [];
      }

      if(!response.childResponses){
        response.childResponses = [];
      }

      question.responses.push(response);
      this.setState({
        request: {
          ...this.state.request,
          notifyUsers: this.uniqueResponseUsers(response)
        }
      });

      this.onQuestionUpdated(question);
    } else if(response.parentResponseId) {
      for(var i = 0; i < this.state.request.questions.length; i++) {
        let question = this.state.request.questions[i];

        let responseIndex = question.responses.findIndex(r =>
          r.id === response.parentResponseId);

        if(responseIndex !== -1) {
          if(!question.responses[responseIndex].childResponses){
            question.responses[responseIndex].childResponses = [];
          }

          question.responses[responseIndex].childResponses.push(response);
          this.onQuestionUpdated(question);
          break;
        }
      }
    }
  }

  onResponseUpdated(response) {
    if(response.questionId){
      let question = this.state.request.questions
        .find(q => q.id === response.questionId);

      const responseIndex = question.responses.findIndex(r =>
        r.id === response.id);
      var ret = question.responses.splice(responseIndex, 1, response);
      response.childResponses = ret[0].childResponses;

      this.setState({
        request: {
          ...this.state.request,
          notifyUsers: this.uniqueResponseUsers(response)
        }
      }, () => {
        this.onQuestionUpdated(question);
      });
    } else if(response.parentResponseId){
      for(var i = 0; i < this.state.request.questions.length; i++) {
        let question = this.state.request.questions[i];

        let responseIndex = question.responses.findIndex(r =>
          r.id === response.parentResponseId);

        if(responseIndex !== -1) {
          let responseResponseIndex = question.responses[0].childResponses
            .findIndex(rr => rr.id === response.id);

          question.responses[responseIndex].childResponses.splice(
            responseResponseIndex,
            1,
            response
          );

          this.setState({
            request: {
              ...this.state.request,
              notifyUsers: this.uniqueResponseUsers(response)
            }
          }, () => {
            this.onQuestionUpdated(question);
          });

          break;
        }
      }
    }
  }

  onResponseDeleted() {
    this.onRequestChanged();
  }

  render() {
    let editUrl = `/requests/${this.state.request.id}/edit`;

    return <>
      <div className="page-header">
        <div className="pull-right" style={{display: this.state.isLoadingRequest ? 'none' : 'inherit'}}>
          <Button onClick={this.printRequest.bind(this)}>
            <FontAwesomeIcon icon={faPrint}></FontAwesomeIcon> Print
          </Button>
          <If condition={this.state.request.statusId < 3}>
            <Link className="btn btn-default edit-request-button" to={editUrl}>
              { this.state.isSubmitting ?
                <> <LoadingSpinner /> Completing </>
                : <> <FontAwesomeIcon icon={faPencilAlt}></FontAwesomeIcon> Edit Request </>}
            </Link>
          </If>
        </div>
        <h1>Request #{this.props.match.params.id}</h1>
      </div>

      <div className="request">
        {this.renderRequest()}
      </div>
    </>;
  }

  renderRequest(){
    if(this.state.isLoadingRequest){
      return <LoadingSpinner />;
    }

    const { request } = this.state;

    let searchTerm = parseQuery(window.location.search).search;
    return <>
      <table className="request-details">
        <tbody>
          <tr>
            <td>
              <h2>
                Request Details
              </h2>
            </td>
            <td style={{paddingTop: '15px'}}>
              <Label text={request.status.description} context="default"></Label>
            </td>
          </tr>
          <tr>
            <td>
              Notify Users:
            </td>
            <td>
              <UserDisplayNames userEmails={request.notifyUsers} />
            </td>
          </tr>
          <tr>
            <td>
              Customer:
            </td>
            <td>
              {request.customer}
            </td>
          </tr>
          <tr>
            <td>
              Subject:
            </td>
            <td>
              <Highlight search={searchTerm}>
                {request.subject}
              </Highlight>
            </td>
          </tr>
          <tr>
            <td>
              Market Channel:
            </td>
            <td>
              {request.marketChannel.name}
            </td>
          </tr>
            <tr>
            <td>
              Created By:
            </td>
            <td className="created-by">
              {this.renderCreatedBy()}
            </td>
          </tr>
          {this.editedByString()}
          <tr>
            <td>
              Required By:
            </td>
            <td>
              {moment(request.requiredBy).format(DateUtils.atFormat)}
            </td>
          </tr>
          <tr>
            <td>
              Date/Time Comments:
            </td>
            <td>
              {request.requiredByComments}
            </td>
          </tr>
          <tr>
            <td>
              Similar Requests:
            </td>
            <td>
              <ul className="list-unstyled">
                {request.similarRequests.map(r =>
                  <li key={r.id}>
                    <Link to={`/requests/${r.id}`}>
                      {`${r.id} - ${r.customer} - ${r.subject}`}
                    </Link>
                  </li>
                )}
              </ul>
            </td>
          </tr>
          <tr style={{display: request.attachments.length > 0 ? 'table-row' : 'none'}}>
            <td>
              Attachments:
            </td>
            <td>
              <AttachmentList
                style={{display: request.attachments ? 'inherit' : 'none'}}
                attachments={request.attachments}
                getFile={this.getAttachmentFile.bind(this)}
                />
            </td>
          </tr>
          <tr>
            <td>
               Requirements:
            </td>
            <td></td>
           </tr>
        </tbody>
      </table>
      <div className="rich-text-display" >
        <Highlight search={searchTerm} innerHtml={request.requirements}/>
      </div>
      <div className="space-stack-xl" ref={this.productsRef}>
        <ProductsIndex products={request.products}
          onProductCreated={this.onProductCreated.bind(this)}
          onProductDeleted={this.onProductDeleted.bind(this)}
          onProductUpdated={this.onProductUpdated.bind(this)}
          request={request}
          disabled={this.requestIsComplete()}/>
      </div>
      <div className="space-stack-xl">
        <QuestionIndex questions={request.questions}
          products={request.products}
          requestNotifyUsers={request.notifyUsers}
          requestStatus={request.status}
          onQuestionCreated={this.onQuestionCreated.bind(this)}
          onQuestionDeleted={this.onQuestionDeleted.bind(this)}
          onQuestionUpdated={this.onQuestionUpdated.bind(this)}
          onResponseCreated={this.onResponseCreated.bind(this)}
          onResponseUpdated={this.onResponseUpdated.bind(this)}
          onResponseDeleted={this.onResponseDeleted.bind(this)}
          expandAll={this.state.expandAllQuestions}
          disabled={this.requestIsComplete()} />
      </div>
      <div className="space-stack-xl">
        { request.statusId === 1 ?
          this.renderPostButton()
          : null
        }
      </div>
      <ApproveRequestButtons request={request}
        onRequestChanged={this.onRequestChanged.bind(this)} />
    </>;
  }

  requestIsComplete() {
    return this.state.request.statusId > 2;
  }

  renderPostButton() {
    if (this.state.request.createdBy !== this.currentUser.oid) {
      return null;
    }

    const hasQuestions = this.state.request.questions.filter(q => q.statusId !== 1).length > 0;
    const disabled = !hasQuestions || this.state.isPosting;

    return <div className="pull-right">
      <button
        className="btn btn-primary"
        onClick={this.postAndNotify.bind(this)}
        disabled={disabled}
        title={ hasQuestions ? null: "At least one posted Question is required to Post Request"}>
        { this.state.isPosting ? <span><LoadingSpinner style={{color: '#ffffff'}}/>&nbsp;</span> : null }
        Post Request & Notify
      </button>
    </div>;
  }

  renderCreatedBy() {
    let value = [
      UserUtils.displayNameFor(this.state.request.createdByUser)
    ];

    if (this.state.request.statusId > 1) {
      value.push(`on ${moment(this.state.request.submittedOn).format(DateUtils.atFormat)}`);
    }

    return value.join(' ');
  }

  postAndNotify() {
    Dialog.confirm({
      submitText: 'Continue',
      message: 'Posting this request will change its status to "In Progress" and notify all users and responders tagged on the request.',
      callback: this.submitRequest.bind(this)
    });
  }

  submitRequest() {
    this.setState({
      isPosting: true
    });

    return RequestService.submit(this.props.match.params.id)
      .then(r => r.json())
      .then(response => {
        if(response.errors){
          Dialog.alert({
            context: 'error',
            message: response.errors[""][0],
          });

          this.state({
            isPosting: false,
          });
          return;
        }

        this.setState({
          isPosting: false,
          request: {
            ...this.state.request,
            submittedOn: response.submittedOn,
            statusId: 2,
            status: { id: 2, description: 'In Progress'}
          }
        });

        Dialog.alert({
          context: 'success',
          message: 'This request has been successfully posted and tagged users have been notified.'
        });
    });
  }

  printRequest() {
    this.setState(
      { expandAllQuestions: true },
      () => setTimeout(() => window.print(), 1));
  }

  onBeforePrint() {
    if (!this.state.expandAllQuestions) {
      this.setState({ expandAllQuestions: true });
    }
  }

  onAfterPrint() {
    if (this.state.expandAllQuestions) {
      this.setState({ expandAllQuestions: false });
    }
  }

  getAttachmentFile(attachmentId) {
    return RequestService.getAttachmentFile(this.state.request.id, attachmentId);
  }

  editedByString() {
    const { request } = this.state;
    let lastUpdated = request.lastUpdatedAt;
    let posted = request.statusId !== 1;
    if(posted && lastUpdated && lastUpdated > request.submittedOn) {
      let dateText = moment(lastUpdated).format(DateUtils.atFormat);
      let text = `${UserUtils.displayNameFor(request.lastUpdatedByUser)} on ${dateText}`;
      return <tr>
        <td>
          Edited By:
        </td>
        <td className="created-by">
          {text}
        </td>
      </tr>;
    }
  }
}

export default RequestsShow;
