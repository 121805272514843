class Config {
  mergedConfig;

  getValues() {
    let localConfig;

    if (this.mergedConfig) {
      return Promise.resolve(this.mergedConfig);
    }

    // merge and return settings files from front-end and back-end projects
    return this.readLocalConfig()
      .then(config => {
        localConfig = config;
        return this.readApiConfig(localConfig.api.url);
      })
      .then(apiConfig => {
        this.mergedConfig = {...localConfig, ...apiConfig };
        return this.mergedConfig;
      });
  }

  readApiConfig(apiUrl) {
    return fetch(`${apiUrl}/config`)
    .then(r => r.json());
  }

  readLocalConfig() {
    return fetch('/config.json')
    .then(r => r.json());
  }
}

export default new Config();
