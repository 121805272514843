import config from './config';
import adal from './adal';
import { Dialog } from 'Dialog';

export default class ServiceBase {
  async get(path) {
    let attrs = await this.defaultAttributes();
    attrs.method = 'GET';

    let response;

    try {
      response = await fetch(await this.url(path), attrs);
      this.handle500s(response);
      return await response.json();
    } catch (e){
      Dialog.alert({
        context: 'error',
        message: `There was an error retrieving ${this.displayName}`
      });
      console.error(`Error calling ${path}`, response, e);
    }
    return null;
  }

  async post(path, data){
    let attrs = await this.defaultAttributes();
    attrs.method = 'POST';
    attrs.headers.append('Content-Type', 'application/json');
    attrs.body = JSON.stringify(data);

    let response = await fetch(await this.url(path), attrs);
    this.handle500s(response);
    return response;
    }

  async postFile(path, data) {
    let attrs = await this.defaultAttributes();
    attrs.method = 'POST';
    attrs.body = data;

    let response = await fetch(await this.url(path), attrs);
    this.handle500s(response);
    return response;
  }

  async getFile(path) {
    let attrs = await this.defaultAttributes();
    attrs.method = 'GET';

    let response = await fetch(await this.url(path), attrs);
    this.handle500s(response);
    return response;
  }

  async put(path, data){
    let attrs = await this.defaultAttributes();
    attrs.method = 'PUT';
    attrs.headers.append('Content-Type', 'application/json');
    attrs.body = JSON.stringify(data || {});

    let response = await fetch(await this.url(path), attrs);
    this.handle500s(response);
    return response;
  }

  async delete(path){
    let attrs = await this.defaultAttributes();
    attrs.method = 'DELETE';

    let response = await fetch(await this.url(path), attrs);
    this.handle500s(response);
    return response;
  }

  async defaultHeaders(){
    let token = await adal.acquireToken();
    let headers = new Headers();

    headers.append('Authorization', `bearer ${token}`);
    headers.append('Accept', 'application/json');

    return headers;
  }

  async defaultAttributes(){
    let headers = await this.defaultHeaders();

    return {
      method: 'PUT',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };
  }

  async url(path){
    let conf = await config.getValues();
    return `${conf.api.url}${path}`;
  }

  handle500s(request){
    if(request.status >= 500 && request.status < 600) {
      Dialog.alert({
        context: 'error',
        message: 'There was an unexpected error with what you were doing. Please send a ticket to the help desk with the URL of the page, and what you were trying to accomplish.'
      });
    }
  }
}
