import AuthenticationContext from 'adal-angular';
import Config from './config';

class Adal {
  // For logging in and out, use the acquireToken and logOut methods, respectively, found below.
  getContext() {
    if (this.context) {
      return Promise.resolve(this.context);
    }

    return Config.getValues()
      .then(config => {
        this.config = config;
        const adalContext = new AuthenticationContext({
          tenant: config.azureAd.tenantId,
          clientId: config.azureAd.clientId,
          navigateToLoginRequestUrl: false,
          cacheLocation: 'localStorage' // enable this for IE, as sessionStorage does not work for localhost.
        });

        if (adalContext.isCallback(window.location.hash)) {
          adalContext.handleWindowCallback();

          //remove hash from the url without navigation.
          if('pushState' in window.history){
            window.history.pushState("", document.title, window.location.pathname + window.location.search);
          }
        }

        this.context = adalContext;
        return this.context;
      });
  }

  getCachedContext() {
    if (this.context) {
      return this.context;
    }

    return false;
  }

  acquireToken() {
    return this.getContext().then(context => {
      return new Promise((resolve, reject) => {
        // acquireToken gets the cached token if it is available and not expired, otherwise it retrieves a new one
        context.acquireToken(this.config.azureAd.clientId, (errorDescription, adToken, error) => {
          if (error) {
            this.context.login();
            reject(error);
          } else {
            resolve(adToken);
          }
        });
      });
    });
  }

  logOut() {
    this.getCachedContext().logOut();
  }
}

export default new Adal();
