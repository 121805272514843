import ServiceBase from './serviceBase';

class DraftService extends ServiceBase {
  displayName = 'Drafts';

  getAll(){
    return super.get('/drafts');
  }
}

export default new DraftService();
