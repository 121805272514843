//make sure all the polyfil imports are at the top, so they run before the code
import 'core-js/features/object/values';
import 'core-js/features/array/includes';
import 'core-js/features/array/flat-map';
import 'core-js/features/array/find';
import 'core-js/features/string/repeat';
import 'core-js/features/symbol/iterator';
import 'react-app-polyfill/ie11';

import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { Router } from 'react-router-dom';
import './index.css';
import App from './App';
import UnAuthorized from './UnAuthorized';
import Adal from './services/adal';
import * as serviceWorker from './serviceWorker';
import { createBrowserHistory } from 'history';

import 'tinymce/tinymce';
import 'tinymce/themes/silver/theme';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/table';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/lists';

const rootElement = document.getElementById('root');
const history = createBrowserHistory();

ReactGA.initialize('UA-131903096-1');

let setupGA = () => {
  const userProfile = Adal.getCachedContext()
    .getCachedUser()
    .profile;

  ReactGA.set({
    userId: userProfile.oid
  });

  history.listen(location => {
    ReactGA.pageview(location.pathname + location.search);
  });

  return userProfile;
};

let renderApp = function(token) {
  let startingPoint = <Router history={history}>
    <App />
  </Router>;

  if(token.roles === undefined){
    startingPoint = <UnAuthorized />;
  }

  ReactDOM.render(startingPoint, rootElement);
};

Adal.acquireToken()
  .then(setupGA)
  .then(renderApp)
  .catch(e => {
    let desc = `Login failed: ${e}`;
    console.log(desc);
    ReactGA.exception({
      description: desc,
      fatal: true
    });
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
