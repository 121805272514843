import React, {Component} from 'react';
import moment from 'moment';
import UserUtils from 'utils/user';
import DateUtils from 'utils/date';

class FormattedDate extends Component {
  isEdited() {
    return this.props.lastUpdatedAt != null && this.props.lastUpdatedAt !== '0001-01-01 00:00:00.0000000+00:00';
  }

  renderDate() {
    let formattedDate;

    if (this.isEdited()) {
      formattedDate = moment(this.props.lastUpdatedAt)
        .format(DateUtils.atFormat);
    } else {
      formattedDate = moment(this.props.createdAt)
        .format(DateUtils.atFormat);
    }

    return formattedDate;
  }

  render() {
    if (this.props.hidden) {
      return null;
    }

    let preText = '';

    if (this.isEdited()) {
      preText += 'Edited By: ';
    }

    if (this.props.user) {
      preText += `${UserUtils.displayNameFor(this.props.user)} on `;
    }

    return <div style={this.props.style} className={this.props.className}>
      {preText} {this.renderDate()}
    </div>;
  }


}

export default FormattedDate;
