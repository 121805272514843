import ServiceBase from './serviceBase';

class ProductMaterialService extends ServiceBase {
  displayName = 'Product Materials';

  async get() {
    return await super.get('/productMaterials');
  }
}

export default new ProductMaterialService();
