import ServiceBase from './serviceBase';
import { stringify as stringifyQuery } from 'query-string';

class RequestService extends ServiceBase {
  displayName = 'Requests';

  async get(id){
    return await super.get(`/requests/${id}`);
  }

  async getAll(queryObject){
    let url = '/requests';

    if(queryObject) {
      url += `?${stringifyQuery(queryObject)}`;
    }

    return await super.get(url);
  }

  async create(request){
    return await super.post('/requests', request);
  }

  async getProducts(id) {
    return await super.get(`/requests/${id}/products`);
  }

  async submit(id) {
    return await super.put(`/requests/${id}/submit`);
  }

  async update(request) {
    return await super.put(`/requests/${request.id}`, request);
  }

  async delete(id) {
    return await super.delete(`/requests/${id}`);
  }

  async getAttachmentFile(id, attachmentId) {
    return (await super.getFile(`/requests/${id}/attachments/${attachmentId}/file`)).arrayBuffer();
  }

  async getSimilarRequestSearch(search){
    return await super.get(`/requests/similarRequestSearch?search=${search}`);
  }

  async complete(id, statusId) {
    return await super.post(`/requests/${id}/complete`, statusId);
  }
}

export default new RequestService();
