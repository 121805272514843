import Base from './serviceBase';

class QuestionService extends Base {
  displayName = 'Questions';

  async get(requestId) {
    return await super.get(`/questions?requestId=${requestId}`);
  }

  async getOne(questionId) {
    return await super.get(`/questions/${questionId}`);
  }

  async create(question) {
    return await super.post('/questions', question);
  }

  async delete(questionId) {
    return await super.delete(`/questions/${questionId}`);
  }

  async update(question) {
    return await super.put(`/questions/${question.id}`, question);
  }

  async getAttachmentFile(id, attachmentId) {
    return (await super.getFile(`/questions/${id}/attachments/${attachmentId}/file`)).arrayBuffer();
  }
}

export default new QuestionService();
