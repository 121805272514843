import { Component } from 'react';
import { Input } from '@dart-design/core';

class FormBase extends Component {
  constructor(props){
    super(props);

    this.state = {
      errors: {}
    };
  }

  updateField(field){
    return (event) => {
      let value;
      if(event === null || event === undefined || typeof event !== 'object'){
        value = event;
      } else {
        const target = event.target;
        value = event.value || event;
        if(target) {
          value = target.value;
          if(target.type === 'checkbox'){
            value = target.checked;
          }
        }
      }

      let updateErrors = this.state.errors;
      delete updateErrors[field[0].toUpperCase() + field.slice(1)];

      this.setState({
        [field]: value,
        errors: updateErrors
      });
    };
  }

  errorContext(field){
    if(this.errorMessages(field)){
      return Input.contexts.error;
    }

    return null;
  }

  errorMessages(field){
    if(!this.state.errors) {
      return null;
    }

    let key = Object.keys(this.state.errors).find(k =>
      k.toUpperCase() === field.toUpperCase());
    if(this.state.errors[key]){
      return this.state.errors[key][0];
    }

    return null;
  }
}

export default FormBase;
