import React, { Component } from 'react';
import uuid from 'uuid/v4';

export default class ProductsTableCell extends Component {
  render () {
    let cellData;

    if (this.props.items) {
      cellData = <div>
        {this.props.items.map(item => {
          if (item.value) {
            return <div key={uuid()}>
              <b>{this.getLabelText(item)}</b>
              {item.value}</div>;
          }
          return null;
        })}
      </div>;
    }

    return (
      <div className={this.props.className}>
        {cellData}
        {this.props.children}
      </div>
    );
  }

  getLabelText(item) {
    if (item.label) {
      return `${item.label}: `;
    }

    if (item.mobileLabel) {
      return <span className="hidden-sm hidden-md hidden-lg">{item.mobileLabel}: </span>;
    }
  }
}
