import UserService from 'services/user';

class UserUtils {
  static async emailsToDisplayNames(email) {
    let users = await UserService.get();

    if(Array.isArray(email)) {
      return email
        .map(e => {
          let user = this.findUserFromEmail(users, e);
          return user ? (user.displayName || e) : e;
        })
        .filter(user => !!user);
    }

    return this.findUserFromEmail(users, email).displayName;
  }

  static findUserFromEmail(users, email) {
    if(!email) {
      return {};
    }
    return users.find(u => !!u.email && u.email.toLowerCase() === email.toLowerCase()) || '';
  }

  static mapEmailStringToArray(userEmailsString, availableResponders) {
    if (!userEmailsString || !availableResponders) {
      return [];
    }

    let userEmails = userEmailsString.split(';');

    return userEmails
      .map(u => {
        let availableUser = availableResponders.find(r => r.email === u);

        if(availableUser){
          return {
            value: availableUser.email,
            label: availableUser.displayName
          };
        } else {
          return {
            value: u,
            label: u
          };
        }
      });
  }

  static displayNameFor(userObject){
    if(userObject == null || userObject.displayName == null){
      return "User Not Found";
    }
    return userObject.displayName;
  }
}

export default UserUtils;
