import React from 'react';
import FormBase from 'components/FormBase';
import MarketChannelService from 'services/marketChannel';
import CustomerService from 'services/customer';
import RequestService from 'services/request';
import UserService from 'services/user';
import { Input, Select, TextArea, Button, AutoComplete, LoadingSpinner } from '@dart-design/core';
import { withRouter } from 'react-router';
import { Dialog } from 'Dialog';
import RTE from 'components/RichTextEditor';
import moment from 'moment';
import FileUploadButton from 'components/attachments/FileUploadButton';
import AttachmentList from 'components/attachments/AttachmentList';
import AttachmentService from 'services/attachment';
import { Link } from 'react-router-dom';
import DeleteButton from 'components/DeleteButton';
import If from 'components/IfCondition';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import UserUtils from 'utils/user';

const DATEFORMAT = 'M/D/YYYY';
const TIMEFORMAT = 'h:mm A';

class RequestsNew extends FormBase {
  constructor(props){
      super(props);

    this.state = Object.assign({
      marketChannels: [],
      customer: '',
      subject: '',
      marketChannelId: null,
      requiredDate: '',
      requiredTime: '',
      requiredByComments: '',
      customerSuggestionList: [],
      statusId: 1,
      requirements: '',
      users: [],
      notifyUsers: [],
      isLoading: true,
      attachments: [],
      requests: [],
      similarRequests: [],
      similarRequestsSearch: '',
      isGettingSimilarRequests: false,
    }, this.state);
  }

  render() {
    if(this.state.statusId > 2){
      return <div>
        {this.renderHeader()}
        <p>
          You cannot edit anything on a completed request.
        </p>
        <Link to={`/requests/${this.props.match.params.id}`}
          className="btn btn-default">
          Go To Request
        </Link>
      </div>;
    }

    if(this.state.isLoading) {
      return <>
        {this.renderHeader()}
        <LoadingSpinner/>
      </>;
    }

    return <>
      {this.renderHeader()}
      <h2> Request Details </h2>
      <br/>
      <form onSubmit={(e)=>{e.preventDefault(); return false;}}>
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <Select label="Notify Users"
              options={this.state.users}
              defaultValue={this.state.notifyUsers}
              isOptionSelected={this.userSelected.bind(this)}
              multiple
              searchable
              onChange={this.updateField('notifyUsers')}
              context={this.errorContext('notifyUsers')}
              help={this.errorMessages('notifyUsers')}
              placeholder="Select one or more users"
              valueKey="email"
              displayKey="displayName"/>
            <AutoComplete label="Customer"
              type={Input.types.text}
              value={this.state.customer}
              suggestions={this.state.customerSuggestionList}
              onChange={this.updateField('customer')}
              context={this.errorContext('Customer')}
              help={this.errorMessages('Customer')}
              onSuggestionSelected={this.onCustomerSuggestionSelected.bind(this)}
              placeholder="Select one or enter new customer"
              required/>
            <Input label="Subject"
              type={Input.types.text}
              value={this.state.subject}
              onChange={this.updateField('subject')}
              context={this.errorContext('Subject')}
              help={this.errorMessages('Subject')}
              placeholder="Briefly describe the request"
              required/>
            <Select label="Market Channel"
              onChange={this.updateField('marketChannelId')}
              value={this.state.marketChannelId}
              options={this.state.marketChannels}
              valueKey="id"
              displayKey="name"
              placeholder="Select One"
              context={this.errorContext('MarketChannelId')}
              help={this.errorMessages('MarketChannelId')}
              required/>

            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className={this.inputWrapperClass('requiredDate')}>
                  <label className="control-label">
                    Required By Date
                    <em className="required-mark" aria-hidden="true" title="Required">*</em>
                  </label>
                  <Datetime timeFormat={false}
                    value={this.state.requiredDate}
                    onChange={this.updateField('requiredDate')}
                    closeOnSelect={true}
                    dateFormat={DATEFORMAT}
                    inputProps={ {
                      className: 'form-control',
                      placeholder: 'Select a Date',
                    } }/>
                  <If condition={!!this.errorMessages('requiredDate')}>
                    <span className="help-block">
                      {this.errorMessages('requiredDate')}
                    </span>
                  </If>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className={this.inputWrapperClass('requiredTime')}>
                  <label className="control-label">
                    Required By Time
                    <em className="required-mark" aria-hidden="true" title="Required">*</em>
                  </label>
                  <Datetime dateFormat={false}
                    value={this.state.requiredTime}
                    onChange={this.updateField('requiredTime')}
                    closeOnSelect={true}
                    timeFormat={TIMEFORMAT}
                    inputProps={ {
                      className: 'form-control',
                      placeholder: 'Select a Time',
                    } }/>
                  <If condition={!!this.errorMessages('requiredTime')}>
                    <span className="help-block">
                      {this.errorMessages('requiredTime')}
                    </span>
                  </If>
                </div>
              </div>
            </div>
            <TextArea value={this.state.requiredByComments}
              onChange={this.updateField('requiredByComments')}
              label="Date/Time Comments"
              context={this.errorContext('RequiredByComments')}
              help={this.errorMessages('RequiredByContext')}
              placeholder="Include any comments relevant to the required date/time"/>

            <Select label="Similar Requests"
              options={this.state.requests}
              defaultValue={this.state.similarRequests}
              isOptionSelected={this.requestSelected.bind(this)}
              multiple
              searchable
              onChange={this.updateField('similarRequests')}
              context={this.errorContext('similarRequests')}
              help={this.errorMessages('similarRequests')}
              placeholder="Select one or more"
              valueKey="value"
              displayKey="label"
              onInputChange={this.getSimilarRequests.bind(this)}
              isLoading={this.state.isGettingSimilarRequests}
              noOptionsMessage={this.similarRequestsNoOptionsMessage.bind(this)}/>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <RTE value={this.state.requirements}
              onChange={this.updateField('requirements')}
              label="Requirements"
              required
              context={this.errorContext('text')}
              help={this.errorMessages('text')} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <AttachmentList
              isEditing={true}
              attachments={this.state.attachments}
              toggleDeleteAttachment={this.toggleDeleteAttachment.bind(this)}
              getFile={this.getAttachmentFile.bind(this)}>
            </AttachmentList>
          </div>
          <div className="col-md-12">
            <FileUploadButton style={{marginLeft: '0'}} handleFile={this.attachAttachment.bind(this)} />
          </div>
        </div>
      </form>
      <div className="flexible horizontal space-stack-lg">
        <div className="grows">
          <If condition={this.isEditing()}>
            <DeleteButton onDelete={this.deleteRequest.bind(this)}
                          isDisabled={this.state.hasQuestionResponses}
                          disabledTooltipText="Requests cannot be deleted once a response has been posted">
                Delete Request
            </DeleteButton>
          </If>
        </div>
        <div>
          <Button onClick={this.cancel.bind(this)}>
            Cancel
          </Button>
          <Button onClick={this.saveAsDraft.bind(this)}>
            Save & Close
          </Button>
          <Button onClick={this.saveAndContinue.bind(this)} type={Button.types.primary}>
            Save & Continue
          </Button>
        </div>
      </div>
    </>;
  }

  inputWrapperClass(field) {
    let context = this.errorContext(field);
    let str = 'form-group';

    if(Input.contexts[context]) {
      str += ` has-${Input.contexts[context]}`;
    }

    return str;
  }

  renderHeader() {
    let headerStr = 'New Request';

    if(this.isEditing()) {
      headerStr = `Edit Request #${this.props.match.params.id}`;
    }

    return <>
      <div className="page-header">
        <h1>{headerStr}</h1>
      </div>
    </>;
  }

  isEditing() {
    return !!this.props.match.params.id;
  }

  componentDidMount(){
    var promises = [
      MarketChannelService.get(),
      CustomerService.get(),
      UserService.get()
    ];

    if(this.isEditing()) {
      promises.push(RequestService.get(this.props.match.params.id));
    }

    return Promise.all(promises)
      .then(([channels, customers, users, request]) => {
        let state = {
          marketChannels: channels,
          customerSuggestionList: customers,
          users: users,
          isLoading: false
        };
        if(request) {
          state.customer = request.customer;
          state.subject = request.subject;
          state.marketChannelId = request.marketChannelId;

          state.requiredDate = moment(request.requiredBy);
          state.requiredTime = moment(request.requiredBy);
          state.requiredByComments = request.requiredByComments;
          state.requirements = request.requirements;
          state.similarRequests = this.requestsToSimilarRequests(request.similarRequests);
          state.statusId = request.statusId;

          state.hasQuestionResponses =
            request.questions.find(q => !!q.responses && q.responses.length > 0) !== undefined;

          let userEmails = request.notifyUsers.split(';');
          state.notifyUsers = users
            .filter(r => userEmails.includes(r.email))
            .map(r => {
              return {
                value: r.email,
                label: UserUtils.displayNameFor(r)
              };
            });

            state.attachments = request.attachments;
        }

        this.setState(state);
    });
  }

  stateToRequest(){
    let requiredByStr = null;
    if(this.state.requiredDate && this.state.requiredTime){
      let requiredBy = new Date(
        this.state.requiredDate.year(),
        this.state.requiredDate.month(),
        this.state.requiredDate.date(),
        this.state.requiredTime.hour(),
        this.state.requiredTime.minute(),
      );

      requiredByStr = requiredBy.toISOString();
    }

    let similarRequestIds = this.state.similarRequests.map(r => {
      return { similarRequestId: r.value };
    });

    return {
      id: this.props.match.params.id || undefined,
      customer: this.state.customer,
      subject: this.state.subject,
      marketChannelId: this.state.marketChannelId,
      requiredBy: requiredByStr,
      requiredByComments: this.state.requiredByComments,
      statusId: this.state.statusId,
      requirements: this.state.requirements,
      notifyUsers: this.state.notifyUsers.map(r => r.value).join(';'),
      similarRequestIds: similarRequestIds
    };
  }

  saveAndContinue() {
    return this.submitRequest(
      data => `/requests/${data.id}`
    );
  }

  saveAsDraft() {
    return this.submitRequest(
      () => '/requests'
    );
  }

  deleteRequest() {
    Dialog.confirm({
      message: 'Deleting this request cannot be undone.',
      submitText: 'Delete',
      cancelText: 'Cancel',
      callback: () => {
        this.setState({isLoading: true});
        RequestService
          .delete(this.props.match.params.id)
          .then(() => {
            this.setState({isLoading: false});
            this.props.history.push(`/requests`);
          });
      }
    });
  }

  cancel() {
    let path ='/requests';
    if(this.isEditing()) {
      path += `/${this.props.match.params.id}`;
    }

    Dialog.confirm({
      message: 'Your changes will be lost. Continue?',
      submitText: 'Yes',
      cancelText: 'No',
      callback: () => this.props.history.push(path)
    });
  }

  submitRequest(destinationFn){
    let timeErrors;
    if(!moment.isMoment(this.state.requiredDate)){
      if(!timeErrors) {
        timeErrors = {};
      }

      timeErrors.requiredDate = [`Incorrect format. ${DATEFORMAT} expected`];
    }

    if(!moment.isMoment(this.state.requiredTime)){
      if(!timeErrors) {
        timeErrors = {};
      }

      timeErrors.requiredTime = [`Incorrect format. ${TIMEFORMAT} expected`];
    }

    if(timeErrors) {
      let errors = Object.assign({}, this.state.errors, timeErrors);
      this.setState({errors});
      return;
    } else {
      let errors = this.state.errors;
      errors.requiredTime = undefined;
      errors.requriedDate = undefined;
      this.setState({errors});
    }

    let requestCall = RequestService.create.bind(RequestService);
    if(this.isEditing()) {
      requestCall = RequestService.update.bind(RequestService);
    }

    this.setState({isLoading: true});

    return requestCall(this.stateToRequest())
      .then(response => {
        if(!response.ok) {
          response.json().then((data) => {
            this.setState({
              errors: data.errors
            });
          });
        } else {
          response.json()
            .then((data) => {
              return this.saveAttachments(data.id).then(() => data);
            })
            .then((data) => {
              this.props.history.push(destinationFn(data));
            });
        }
      })
      .catch(e => {
        console.log(e); //should notie this
      })
      .then(() => {
        this.setState({isLoading: false});
      });
  }

  getSimilarRequests(value) {
    let state = {
      similarRequestsSearch: value
    };

    if(value && value.length >= 3){
      state.isGettingSimilarRequests = true;

      RequestService.getSimilarRequestSearch(value)
        .then(requests => {
          this.setState({
            isGettingSimilarRequests: false,
            requests: this.requestsToSimilarRequests(requests)
          });
      });
    } else {
      state.requests = [];
    }

    this.setState(state);
  }

  requestsToSimilarRequests(requests) {
    return requests.map(r => {
      return { value: r.id, label: `${r.id} - ${r.customer} - ${r.subject}`};
    });
  }

  onCustomerSuggestionSelected(event, { suggestion }){
    this.setState({
      customer: suggestion
    });
  }

  userSelected(user, options){
    return options.map(r => r.value).includes(user.value);
  }

  requestSelected(request, options){
    return options.map(r => r.value).includes(request.value);
  }

  attachAttachment(file) {
    this.setState({attachments:  [...this.state.attachments, file]});
  }

  saveAttachments(requestId) {
    let promises = [Promise.resolve()];

    this.state.attachments.forEach(attachment => {
      if (!attachment.id && !attachment.isMarkedForDeletion) {
        promises.push(AttachmentService.create(requestId, attachment));
      }

      if (this.isEditing() && attachment.id && attachment.isMarkedForDeletion) {
        promises.push(AttachmentService.deleteRequestAttachment(requestId, attachment.id));
      }
    });

    return Promise.all(promises);
  }

  toggleDeleteAttachment(attachment) {
    let attachments = this.state.attachments;
    let _attachment = attachments.find((a) => a === attachment);
    _attachment.isMarkedForDeletion = !_attachment.isMarkedForDeletion;

    this.setState({
      attachments: attachments
    });
  }

  getAttachmentFile(attachmentId) {
    if (this.isEditing()) {
      return RequestService.getAttachmentFile(this.props.match.params.id, attachmentId);
    }
    return null;
  }

  similarRequestsNoOptionsMessage(obj) {
    if(!obj.inputValue || obj.inputValue.length < 3) {
      return "Please enter at least 3 characters";
    }

    return "There are no requests that meet your search";
  }
}

export default withRouter(RequestsNew);
