import React from 'react';
import FormBase from '../FormBase';
import { Select, Button, LoadingSpinner } from '@dart-design/core';
import { withRouter } from 'react-router';
import QuestionService from 'services/question';
import UserService from 'services/user';
import GroupService from 'services/group';
import UserUtils from 'utils/user';
import { Dialog } from 'Dialog';
import Adal from 'services/adal';
import RTE from 'components/RichTextEditor';
import FileUploadButton from 'components/attachments/FileUploadButton';
import AttachmentList from 'components/attachments/AttachmentList';
import If from 'components/IfCondition';
import DeleteButton from 'components/DeleteButton';
import Display from 'utils/display';

class QuestionsForm extends FormBase {
  constructor(props){
    super(props);

    this.formRef = React.createRef();

    this.state = Object.assign({
      id: props.match.params.questionId,
      responders: [],
      groupId: '',
      questionProducts: [],
      text: '',
      availableResponders: [],
      availableGroups: [],
      availableProducts: props.products || [],
      isLoading: true,
      statusId: 1,
      isSubmitting: false,
      attachments: [],
    }, this.state);
  }

  render() {
    if(this.props.disabled){
      return <div className="new-question-form" ref={this.formRef}>
        You cannot edit anything on a completed request.
      </div>;
    }

    if(this.state.isLoading){
      return <div className="new-question-form" ref={this.formRef}>
        <LoadingSpinner/>
        &nbsp;
        Loading Question Form
      </div>;
    }

    return <div className="new-question-form" ref={this.formRef}>
      <form id="questionForm">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <Select label="Responders"
              options={this.state.availableResponders}
              defaultValue={this.state.responders}
              isOptionSelected={this.responderSelected.bind(this)}
              multiple
              searchable
              onChange={this.updateField('responders')}
              context={this.errorContext('responders')}
              help={this.errorMessages('responders')}
              placeholder="Select one or more responders"
              required
              valueKey="email"
              displayKey="displayName"/>

            <Select label="Notify Users"
              options={this.state.availableResponders}
              defaultValue={this.state.notifyUsers}
              isOptionSelected={this.responderSelected.bind(this)}
              multiple
              searchable
              onChange={this.updateField('notifyUsers')}
              context={this.errorContext('notifyUsers')}
              help={this.errorMessages('notifyUsers')}
              placeholder="Select one or more users"
              valueKey="email"
              displayKey="displayName"/>

            <Select label="Group"
              options={this.state.availableGroups}
              value={this.state.groupId}
              searchable
              onChange={this.updateField('groupId')}
              context={this.errorContext('groupId')}
              help={this.errorMessages('groupId')}
              placeholder="Select one"
              required
              valueKey="id"
              displayKey="name"/>

            <Select label="Related Products"
              options={this.state.availableProducts}
              defaultValue={this.state.questionProducts}
              isOptionSelected={this.productSelected.bind(this)}
              multiple
              searchable
              onChange={this.updateField('questionProducts')}
              context={this.errorContext('questionProducts')}
              help={this.errorMessages('questionProducts')}
              placeholder="Select one or more product"
              required
              valueKey="id"
              displayKey="stockCode"/>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <RTE value={this.state.text}
              onChange={this.updateField('text')}
              label="Question"
              required
              context={this.errorContext('text')}
              help={this.errorMessages('text')} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <AttachmentList
              isEditing={true}
              attachments={this.state.attachments}
              toggleDeleteAttachment={this.toggleDeleteAttachment.bind(this)}
              getFile={this.getAttachmentFile.bind(this)}>
            </AttachmentList>
          </div>
          <div className="col-md-12">
            <FileUploadButton style={{marginLeft: '0'}} handleFile={this.attachAttachment.bind(this)} />
          </div>
        </div>
      </form>
      <div className="row">
        <div className="col-md-12">
          <If condition={!!this.props.match.params.questionid}>
            <DeleteButton onDelete={this.deleteQuestion.bind(this)}
                          isDisabled={this.hasResponses()}
                          disabledTooltipText={this.getDeleteButtonTooltip()}
                          >
              Delete Question
            </DeleteButton>
          </If>
          <div className="pull-right">
            <Button onClick={this.cancel.bind(this)}
              disabled={this.state.isSubmitting}>
              Cancel
            </Button>
            { this.state.statusId === 1 ?
              <Button onClick={this.saveAsDraft.bind(this)}
                disabled={this.state.isSubmitting}>
                { this.state.isSubmitting ?
                  <span><LoadingSpinner/>&nbsp;</span>
                  : null }
                  Save as Draft
              </Button>
              : null }
            <Button onClick={this.postAndNotify.bind(this)}
              type={Button.types.primary}
              disabled={this.state.isSubmitting}>
              { this.state.isSubmitting ?
                <>
                  <LoadingSpinner/>
                  &nbsp;
                </>
                : null }
              { this.props.match.params.questionid ?
                "Update" :
                "Add Question" }
            </Button>
          </div>
        </div>
      </div>
      <br/>
    </div>;
  }

  getDeleteButtonTooltip() {
    let title = null;
    let question = this.props.questions.find(q =>
      q.id === parseInt(this.props.match.params.questionid));
    if(question){
      if (question.responses.length > 0) {
        title='Questions cannot be deleted once a response has been posted';
      } else if (this.state.peopleWithDraftResponses.length > 0) {
        title = 'The following people have drafts on this request: ' +
        this.state.peopleWithDraftResponses.join(', ');
      }
    }
    return title;
  }

  hasResponses() {
    let question = this.props.questions.find(q =>
      q.id === parseInt(this.props.match.params.questionid));

    if (!question || !question.responses) {
      return false;
    }

    return question.responses.length > 0 || this.state.peopleWithDraftResponses.length > 0;
  }

  cancel() {
    Dialog.confirm({
      message: 'Your changes will be lost. Continue?',
      submitText: 'Yes',
      cancelText: 'No',
      callback: this.returnToRequest.bind(this)
    });
  }

  saveAsDraft() {
    this.submitQuestion(1);
  }

  postAndNotify() {
    this.submitQuestion(2);
  }

  async stateToQuestion(statusId){
    let requestId = this.props.match.params.id;

    let attachments = await this.buildAttachments();

    return {
      id: this.state.id,
      requestId: requestId,
      notifyUsers: this.state.notifyUsers.map(nu => nu.value).join(';'),
      responders: this.state.responders.map(r => r.value).join(';'),
      groupId: this.state.groupId,
      questionProducts: this.state.questionProducts.map(p => { return { productid: p.value };}),
      statusId: statusId,
      text: this.state.text,
      attachments: attachments,
    };
  }

  async buildAttachments(){
    return await Promise.all(this.state.attachments.map(async a => {
      if(a.constructor === File) {
        var data = await this.getFileData(a);
        return {
          name: a.name,
          contentType: a.type,
          createdBy: 'some-user',
          fileContent: {
            data: data,
          }
        };
      }

      return Promise.resolve(a);
    }));
  }

  getFileData(file) {
    return new Promise((resolve) => {
      var reader = new FileReader();

      reader.onload = () => {
        var array = [];
        var buffer = new Uint8Array(reader.result);

        for(var i = 0; i < buffer.length; i++){
          array.push(buffer[i]);
        }

        resolve(array);
      };

      reader.readAsArrayBuffer(file);
    });
  }

  async submitQuestion(statusId){
    await new Promise((resolve) => {
      this.setState({
        attachments: this.state.attachments.filter(qa => !qa.isMarkedForDeletion)
      }, resolve);
    });

    let questionCall = QuestionService.create.bind(QuestionService);
    if(this.props.match.params.questionid) {
      questionCall = QuestionService.update.bind(QuestionService);
    }
    this.setState({
      isSubmitting: true
    });

    return questionCall(await this.stateToQuestion(statusId))
      .then(async q => {
        this.setState({
          errors: {}
        });

        if (!q.ok) {
          let errors;
          try {
            errors = (await q.json()).errors;
          }
          catch (e){
            errors = {};
            errors[""] = "There was a problem with your request";
          }

          this.setState({
            errors: errors,
            isSubmitting: false
          });
        } else {
          let question = await q.json();
          question.group = this.state.availableGroups
            .find(g => g.id === question.groupId);

          question.questionProducts.forEach(qp => {
            qp.product = this.state.availableProducts
              .find(p => p.id === qp.productId);
          });
          let adUser = Adal.getCachedContext().getCachedUser().profile;
          question.createdByUser = {
            displayName: adUser.name
          };

          question.responses = [];

          if(this.props.onQuestionEvent){
            this.returnToRequest();
            this.props.onQuestionEvent(question);
          }
        }
      });
  }

  isEditing() {
    return !!this.props.match.params.questionid;
  }

  componentDidMount() {
    let promises = [
      UserService.get(),
      GroupService.get()
    ];

    if(this.isEditing()) {
      promises.push(QuestionService.getOne(this.props.match.params.questionid));
    }

    if(this.formRef.current) {
      Display.scrollToRef(this.formRef);
    }

    return Promise.all(promises)
      .then(([responders, groups, question]) => {

      responders = responders || [];

      if(!this.isEditing() && this.props.requestNotifyUsers) {
        this.setState({
          notifyUsers: UserUtils.mapEmailStringToArray(this.props.requestNotifyUsers, responders)
        });
      }

      let state = {
        availableResponders: responders,
        availableGroups: groups || [],
        isLoading: false
      };

      if(question) {
        let responderEmails = question.responders.split(';');
        state.responders = responders
          .filter(r => responderEmails.includes(r.email))
          .map(r => {
            return {
              value: r.email,
              label: UserUtils.displayNameFor(r)
            };
          });

        state.responders = UserUtils.mapEmailStringToArray(question.responders, responders);
        state.notifyUsers = UserUtils.mapEmailStringToArray(question.notifyUsers, responders);
        state.peopleWithDraftResponses = question.peopleWithDrafts;

        state.groupId = question.groupId;

        let productIds = question.questionProducts.map(qp => qp.productId);
        state.questionProducts = this.state.availableProducts.filter(p => {
          return productIds.includes(p.id);
        }).map(p => {
          return {
            value: p.id,
            label: p.stockCode
          };
        });

        if (!!question.text) {
          state.text = question.text;
        }

        state.statusId = parseInt(question.statusId);
        state.id = question.id;
        state.attachments = question.attachments;
      }

      this.setState(state);
    });
  }

  deleteQuestion() {
    return Dialog
      .confirm({
        callback: () => QuestionService.delete(this.props.match.params.questionid),
        message: 'Deleting this question cannot be undone.',
        submitText: 'Delete'
      })
      .then(request => {
        this.setState({
          errors: {}
        });

        if(request.ok) {
          this.returnToRequest();

          if(this.props.onQuestionDeleted) {
            this.props.onQuestionDeleted(this.props.match.params.questionid);
          }
        } else {
          this.setState({
            errors: {"": 'There was an error deleting this Question. Please try again or submit a service desk ticket with the current URL.'}
          });
        }
      });
  }

  returnToRequest() {
    let requestId = this.props.match.params.id;
    this.props.history.push(`/requests/${requestId}`);
  }

  responderSelected(responder, options){
    return options.map(r => r.value).includes(responder.value);
  }

  productSelected(product, options){
    return options.map(r => r.value).includes(product.value);
  }

  toggleDeleteAttachment(attachment) {
    let attachments = this.state.attachments;
    let _attachment = attachments.find((a) => a === attachment);
    _attachment.isMarkedForDeletion = !_attachment.isMarkedForDeletion;

    this.setState({
      attachments: attachments
    });
  }

  attachAttachment(file) {
    this.setState({attachments:  [...this.state.attachments, file]});
  }

  getAttachmentFile(attachmentId) {
    return QuestionService.getAttachmentFile(this.state.id, attachmentId);
  }
}

export default withRouter(QuestionsForm);
