import {Component} from 'react';
import PropTypes from 'prop-types';

export default class If extends Component {
  static propTypes = {
    condition: PropTypes.bool
  }

  render() {
    if (this.props.condition) {
      return this.props.children;
    }

    return null;
  }
}