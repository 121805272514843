class DisplayUtil {
  scrollToRef(ref) {
    let header = document.getElementsByTagName('header')[0];
    let headerHeight = header.getBoundingClientRect().height;

    let topOfEle = ref.current
      .getBoundingClientRect().y;

    window.scroll({
      top: topOfEle + window.scrollY - headerHeight,
      behavior: 'smooth'
    });
  }
}

export default new DisplayUtil();
