import React, { Component } from 'react';
import { Input } from '@dart-design/core';
import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './RichTextEditor.scss';
import { Editor } from '@tinymce/tinymce-react';

const pastePluginOptions = {
  paste_retain_style_properties: 'all',
  paste_webkit_styles: 'all',
  paste_enable_default_filters: false,
  paste_filter_drop: false,
  paste_remove_styles_if_webkit: false,
};

class RTE extends Component {
  handleEditorChange = (e) => {
      if (this.props.onChange) {
        this.props.onChange(e.target.getContent());
      }
  }

  toolbar() {
    return this.props.toolbar || 'undo redo | styleselect | fontsizeselect forecolor backcolor | bold italic underline | alignLeft alignCenter alignright justify | bullist numlist indent outdent | table';
  }

  render() {
    return <div className={this.wrapperClass()}>
      <label className="control-label">
        {this.props.label}
        {this.renderRequired()}
      </label>
        <Editor
            initialValue={this.props.value}
            onChange={this.handleEditorChange}
            init={{
              plugins: 'table lists autoresize paste',
              skin: false, //including skin in scss file
              content_css: false, //see line above
              relative_urls : false,
              remove_script_host : false,
              document_base_url: window.location.origin,
              browser_spellcheck: true,
              menubar: false,
              statusbar: false,
              toolbar: this.toolbar(),
              ...pastePluginOptions,
            }}
            editorClassName="form-control" />
      {this.renderHelp()}
    </div>;
  }

  onEditorStateChange(editorState) {
    if(this.props.onChange){
      this.props.onChange(editorState);
    }
  }

  renderRequired() {
    if(this.props.required){
      return <em className="required-mark" aria-hidden="true" title="Required">*</em>;
    }
  }

  renderHelp() {
    if(this.props.help){
      return (
        <span className="help-block">{this.props.help}</span>
      );
    }

    return null;
  }

  wrapperClass(){
    let str = 'form-group';

    if(Input.contexts[this.props.context]){
      str += ` has-${Input.contexts[this.props.context]}`;
    }

    return str;
  }
}

export default RTE;
