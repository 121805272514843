import Base from './serviceBase';

class ResponseService extends Base {
  displayName = 'Response';

  async get(responseId) {
    return await super.get(`/responses/${responseId}`);
  }

  async create(response) {
    return await super.post('/responses', response);
  }

  async delete(responseId) {
    return await super.delete(`/responses/${responseId}`);
  }

  async update(response) {
    return await super.put(`/responses/${response.id}`, response);
  }

  async getAttachmentFile(id, attachmentId) {
    return (await super.getFile(`/responses/${id}/attachments/${attachmentId}/file`)).arrayBuffer();
  }
}

export default new ResponseService();
