import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

export default class DeleteButton extends Component {
  static propTypes = {
    onDelete: PropTypes.func,
    isDisabled: PropTypes.bool,
    disabledTooltipText: PropTypes.string
  }

  render() {
    return <button className="btn btn-transparent"
                   disabled={this.props.isDisabled}
                   title={this.props.disabledTooltipText}
                   onClick={this.props.onDelete.bind(this)}>
      <FontAwesomeIcon icon={faTrash} />
      &nbsp;
      {this.props.children}
    </button>;
  }
}