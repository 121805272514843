import React, { Component } from 'react';
import { UserMenu } from '@dart-design/core';
import { faBars, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";
import Adal from '../services/adal';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        firstName: '',
        lastName: ''
      },
    };

    this.links = [
      {
        active: /\/requests.*/i,
        href: '/',
        text: 'Requests',
      },
      {
        active: /\/drafts.*/i,
        href: '/drafts',
        text: 'My Drafts'
      }
    ];
  }

  componentDidMount() {
    const userProfile = Adal.getCachedContext().getCachedUser().profile;

    this.setState({
      user: {
        firstName: userProfile.given_name,
        lastName: userProfile.family_name
      }
    });
  }

  logOut = () => Adal.logOut();

  renderLinks() {
    const renderedLinks = [];

    this.links.forEach(link => {
      renderedLinks.push(
        <li className={link.active.test(this.props.location.pathname) ? 'active' : ''}
            key={link.text}>
          <Link to={link.href}>
            {link.text}
          </Link>
        </li>
      );
    });

    // link to all the historical documents, external site.
    var siteLink = "https://dartcontainer.sharepoint.com/sites/CFBT/Lists/Lotus%20Notes%20Mkt%20Request%20for%20Quote%20History/AllItems.aspx";
    renderedLinks.push(
      <li key="historicalDocuments">
        <a href={siteLink} target="_blank" rel="noopener noreferrer">
          Archive
          &nbsp;
          <FontAwesomeIcon style={{color: 'white'}} icon={faExternalLinkAlt}></FontAwesomeIcon>
        </a>
      </li>
    );

    renderedLinks.push(
      <div key="logOutButton" className="hidden-sm hidden-md hidden-lg">
        <hr />
        <li>
          <button className="btn-link" onClick={this.logOut}>
            Log Off
          </button>
        </li>
      </div>
    );

    return (renderedLinks);
  }

  toggleMobileNav() {
    const menu = document.querySelector('.nav-menu');
    const isMenuVisible = menu.style.top === '50px';

    menu.style.top = isMenuVisible ? `-${menu.offsetHeight + 1}px` : '50px';
  }

  render() {
    return (
      <header className="navigation two-sm tiers">
        <nav className="tier one bg-dart">
          <div className="container">
            <button onClick={this.toggleMobileNav}
                    className="btn btn-transparent hidden-sm hidden-md hidden-lg" style={{float: 'left'}}>
              <FontAwesomeIcon style={{color: 'white'}} icon={faBars} size="lg" ></FontAwesomeIcon>
            </button>
            <Link className="navbar-brand branding" to="/">
              MMRFQ
            </Link>
            <div className="navbar-text navbar-right">
              <div className="hidden-xs">
                <UserMenu className="hidden-xs" userName={`${this.state.user.firstName} ${this.state.user.lastName}`} style={{"color":"black"}}>
                  <a href="#" className="btn-link" onClick={this.logOut}>Log Off</a>
                </UserMenu>
              </div>
            </div>
          </div>
        </nav>

        <div className="hidden-xs">
          <nav className="tier two bg-dart-dark">
            <div className="container flexible horizontal align-middle">
              <ul className="nav navbar-nav">
                {this.renderLinks()}
              </ul>
            </div>
          </nav>
        </div>
        <div className="nav-menu hidden-sm hidden-md hidden-lg" id="navigationMenu" tabIndex="-1" role="navigation">
          <div className="nav-menu-content" onClick={this.toggleMobileNav}>
            <ul className="nav-menu-list">
              {this.renderLinks()}
            </ul>
          </div>
        </div>
      </header>
    );
  }
}

export default withRouter(Header);
