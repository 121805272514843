import React, { Component } from 'react';
import FileIcon from './FileIcon';
import AttachmentService from 'services/attachment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faUndo } from '@fortawesome/free-solid-svg-icons';

class AttachmentList extends Component {
  render() {
    let label = this.props.isEditing ? <label className="control-label">Attachments</label> : null;

    return (
      <div>
        {label}
        {this.renderBody()}
      </div>
    );
  }

  renderBody() {
    let listStyle = {
      padding: 0,
      listStyleType: 'none'
    };
    if (!this.props.attachments || this.props.attachments.length === 0) {
      return null;
    } else {
      return <ul style={listStyle}>
          {this.props.attachments.map((attachment) =>
            <li key={attachment.id} style={{margin: '5px 0', lineHeight: '1rem'}}>
              <FileIcon
                contentType={attachment.id ? attachment.contentType : attachment.type}
                style={{color: '#404040'}}
                >
              </FileIcon>&nbsp;
              {this.renderAttachmentName(attachment)}
            </li>)}
        </ul>;
    }
  }

  renderAttachmentName(attachment) {
    let deletedStyle = null;

    if (attachment.isMarkedForDeletion) {
      deletedStyle = {
        textDecoration: 'line-through',
        color: '#B8B8B8',
      };
    }

    return (
      <span>
        <button type="button" className="btn btn-link" onClick={() => this.openAttachment(attachment)} style={deletedStyle}>
          {attachment.name}
        </button>
        {this.renderDeleteButton(attachment)}
      </span>
    );
  }

  renderDeleteButton(attachment) {
    if (this.props.isEditing) {
      return (
        <span className="space-inline-sm">
          <button title={attachment.isMarkedForDeletion ? 'Restore' : 'Delete'}
                  type="button"
                  className="btn btn-link"
                  onClick={() => this.props.toggleDeleteAttachment(attachment)}>
            <FontAwesomeIcon className="print-hide" icon={attachment.isMarkedForDeletion ? faUndo : faTrashAlt} />
          </button>
        </span>
      );
    }
    return null;
  }

  openAttachment(attachment) {
    let openAttachment = (file) => {
      let contentType = attachment.contentType || attachment.type;
      AttachmentService.openFile(file, contentType, attachment.name);
    };

    if (attachment.id) {
      this.props.getFile(attachment.id)
      .then(file => {
        openAttachment(file);
      });
    } else {
      return openAttachment(attachment);
    }
  }
}

export default AttachmentList;