import React, { Component } from 'react';
import Response from './Response';

export default class ResponseList extends Component {
  render() {
    return (
      <div className="response-list">
        <h4 className="print-only" style={{display: this.props.responses.length > 0 ? 'default' : 'none'}}>Responses</h4>
        { this.props.responses.map(response => {
          return (
            <Response
              {...this.props}
              onResponseCreated={this.props.onResponseCreated}
              onResponseUpdated={this.props.onResponseUpdated}
              key={response.id}
              response={response}
              disabled={this.props.disabled}>
            </Response>
          );
        })}
      </div>
    );
  }
}
