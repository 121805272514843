import ServiceBase from './serviceBase';

class ProductService extends ServiceBase {
  displayName = 'Products';

  async create(product){
    return await super.post('/products', product);
  }
  async get(productId){
    return await super.get(`/products/${productId}`);
  }
  async update(product){
    return await super.put(`/products/${product.id}`, product);
  }

  async delete(productId){
    return await super.delete(`/products/${productId}`);
  }
}

export default new ProductService();
