import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import Header from 'components/Header';
import Requests from 'components/requests/Index';
import Drafts from 'components/drafts/Index';
import 'dart-design.scss';
import 'App.scss';

class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <div className="App">
        {/* Preload bold and italics fonts */}
        <i></i><b></b>
        <div>
          <Header></Header>
          <div className="container">
            <Route path='(/|/requests)' component={Requests} />
            <Route path='/drafts' component={Drafts} />
          </div>
        </div>
        <footer>
          <div className="container">
            <div className="center-block">
              ©{(new Date()).getFullYear()} Dart Container
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default App;
