import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';

class FileUploadButton extends Component {
  constructor(props) {
    super(props);
    this.fileInputRef = React.createRef();
  }

  render() {
    return (
      <span>
        <input type="file" id="file-uploader" className="hidden" ref={this.fileInputRef} onChange={this.handleFile.bind(this)}></input>
        <button type="button" className={`btn btn-default ${this.props.className}`} onClick={this.selectFile.bind(this)} style={this.props.style}>
          <FontAwesomeIcon icon={faFileUpload} /> Upload File
        </button>
      </span>
    );
  }

  selectFile() {
    this.fileInputRef.current.click();
  }

  handleFile() {
    let file = this.fileInputRef.current.files[0];

    if (file) {
      this.props.handleFile(file);
    }
  }
}

export default FileUploadButton;