import ServiceBase from './serviceBase';

class ProductCategoryService extends ServiceBase {
  displayName = 'Product Categories';

  async get() {
    return await super.get('/productCategories');
  }
}

export default new ProductCategoryService();
