import React, { Component } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import UserUtil from 'utils/user';

const typeSize = 1;
const idSize = 1;
const customerSize = 3;
const createdSize = 2;
const dueSize = 2;
const categorySize = 2;
const buttonSize = 1;

class DraftsList extends Component {
  render() {
    return <div className="draft-list list">
      {this.renderHeader()}
      {this.renderBody()}
    </div>;
  }

  renderHeader() {
    return <div className="header hidden-xs hidden-sm">
      <div className="row">
        <div className={`col-md-${typeSize}`}>
          Type
        </div>
        <div className={`col-md-${idSize}`}>
          Req #
        </div>
        <div className={`col-md-${customerSize}`}>
          <div>Customer</div>
           & Request Subject
         </div>
        <div className={`col-md-${createdSize}`}>
          <div>Request Created By</div>
          & Date
        </div>
        <div className={`col-md-${dueSize}`}>
          <div>Request Due Date</div>
          & Time
        </div>
        <div className={`col-md-${categorySize}`}>
          <div>Categories</div>
          & Materials
        </div>
        <div className={`col-md-${buttonSize}`}></div>
      </div>
    </div>;
  }

  renderBody() {
    return this.props.drafts.map(d => <div className="card card-striped" key={this.getDraftKey(d)}>
      <div className="row">
        <div className={`col-md-${typeSize}`}>
          <span className="visible-xs visible-sm">
            <b>Type: </b>
          </span>
          <span className="draft-type">
            {d.type}
          </span>
        </div>
        <div className={`col-md-${idSize}`}>
          <span className="visible-xs visible-sm">
            <b>Id: </b>
          </span>
          <span className="request-id">
            {d.request.id}
          </span>
        </div>
        <div className={`col-md-${customerSize}`}>
          <div>
            <span className="visible-xs visible-sm">
              <b>Request Customer: </b>
            </span>
            <span className="customer-name">
              <b>{d.request.customer}</b>
            </span>
          </div>
          <span className="visible-xs visible-sm">
            <b>Request Subject: </b>
          </span>
          <span className="request-subject">
            {d.request.subject}
          </span>
        </div>
        <div className={`col-md-${createdSize}`}>
          <div>
            <span className="visible-xs visible-sm">
              <b>Request Created By: </b>
            </span>
            <span className="created-by">
              {UserUtil.displayNameFor(d.request.createdByUser)}
            </span>
          </div>
          <span className="visible-xs visible-sm">
            <b>Request Created At: </b>
          </span>
          <span className="draft-type">
            {d.request.statusId > 1 ? moment(d.request.submittedOn).format('M/D/YYYY [@] h:mma') : null}
          </span>
        </div>
        <div className={`col-md-${dueSize}`}>
          <div>
            <span className="visible-xs visible-sm">
              <b>Request Due Date: </b>
            </span>
            <span className="required-date">
              {moment(d.request.requiredBy).format('M/D/YYYY')}
            </span>
          </div>
          <span className="visible-xs visible-sm">
            <b>Request Due Time: </b>
          </span>
          <span className="required-time">
            {moment(d.request.requiredBy).format('h:mma')}
          </span>
        </div>
        <div className={`col-md-${categorySize}`}>
          <div>
            <span className="visible-xs visible-sm">
              <b>Categories: </b>
            </span>
            <span className="category">
              {d.productCategories.map(pc => pc.name).join(', ')}
            </span>
          </div>
          <span className="visible-xs visible-sm">
            <b>Materials: </b>
          </span>
          <span className="material">
            {d.productMaterials.map(pc => pc.name).join(', ')}
          </span>
        </div>
        <div className={`col-md-${buttonSize} text-right`}>
          <Link className="btn btn-default view-draft-button" to={this.draftPath(d)}>
            Edit
          </Link>
        </div>
      </div>
    </div>);
  }

  getDraftKey(draft) {
    let id;
    switch(draft.type){
      case 'Request':
        id = draft.request.id;
        break;
      case 'Question':
        id = draft.question.id;
        break;
      case 'Response':
        id = draft.response.id;
        break;
      default:
        break;
    }

    return `${draft.type}-${id}`;
  }

  draftPath(draft){
    var url = `/requests/${draft.request.id}`;

    if(draft.question){
      url += `/questions/${draft.question.id}`;

      if(draft.response){
        url += `/responses/${draft.response.id}`;
      }
    }

    url += `/edit`;

    return url;
  }
}

export default DraftsList;
