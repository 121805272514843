import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ProductsTableRow from './ProductsTableRow';
import { Card } from '@dart-design/core';
import { withRouter } from 'react-router';

class ProductsTable extends Component {
  static propTypes = {
    products: PropTypes.array
  };

  static defaultProps = {
    products: []
  };

  render() {
    return(
      <div>
        <div className="list">
          <div className="header hidden-xs">
            <div className="row">
              <div className="col-sm-2">Product</div>
              <div className="col-sm-2">Print & Color</div>
              <div className="col-sm-2">Product Category & Material(s)</div>
              <div className="col-sm-2">Producing Plant</div>
              <div className="col-sm-2">Annual Volume (M)</div>
              <div className="col-sm-2">Packaging Specs</div>
            </div>
          </div>
          { this.renderRows() }
        </div>
      </div>
    );
  }

  renderRows() {
    if (this.props.products.length === 0) {
      return <Card>
        <div className="space-inset-lg" style={{textAlign: 'center'}}>
          No products have been added yet
        </div>
      </Card>;
    }

    return this.props.products.map(product => {
      return <ProductsTableRow product={product} key={product.id}
        disabled={this.props.disabled}/>;
    });
  }
}

export default withRouter(ProductsTable);
