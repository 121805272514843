import ServiceBase from './serviceBase';

class UserService extends ServiceBase {
  displayName = 'Users';

  async get(){
    if(this.dataRecievedOn && this.results) {
      let expiry = 5 * 60 * 1000; // 5 minutes
      let diff = (new Date()) - this.dataRecievedOn;

      if(diff < expiry) {
        return Promise.resolve(this.results);
      }
    }

    let results = await super.get('/users');
    this.dataRecievedOn = new Date();
    this.results = results;
    return results;
  }

  async getRequesters() {
    return await super.get('/users/requesters');
  }
}

export default new UserService();
