import React, {Component} from 'react';
import { Label } from '@dart-design/core';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { parse as parseQuery } from 'query-string';
import UserUtils from 'utils/user';

class ListRow extends Component {
  renderStatus() {
    if (this.props.request.statusId === 1) {
      return <Label context="warning" text={this.props.request.status.description} />;
    } else {
      return this.props.request.status.description;
    }
  }

  render() {
    let search = parseQuery(window.location.search).search;
    let path = `/requests/${this.props.request.id}`;
    if(search) {
      path = {
        pathname: path,
        search: `search=${search}`
      };
    }
    const { request } = this.props;

    let createdByUserName = UserUtils.displayNameFor(request.createdByUser);

    return (
      <div className="card card-striped">
        <div className="row">
          <div className="col-sm-1">
            <span className="visible-xs-inline"><b>Id: </b></span>
            <span className="request-id">
              {request.id}
            </span>
          </div>
          <div className="col-sm-3">
            <div>
              <span className="hidden-sm hidden-md hidden-lg"><b>Customer: </b></span>
              <span className="customer-name">
                <b>
                  {request.customer}
                </b>
              </span>
            </div>
            <div>
              <span className="hidden-sm hidden-md hidden-lg"><b>Subject: </b></span>
              <span className="request-subject">
                {request.subject}
              </span>
            </div>
            <div>
              <span className="request-market-channel">
                <i> - {request.marketChannel.name} </i>
              </span>
            </div>
          </div>
          <div className="col-sm-2">
            <div>
              <span className="hidden-sm hidden-md hidden-lg"><b>Created By: </b></span>
              <span className="created-by">
                {createdByUserName}
              </span>
            </div>
            <span className="hidden-sm hidden-md hidden-lg"><b>Created Date: </b></span>
            <span className="submitted-on">
              {request.statusId > 1 ? moment(request.submittedOn).format('M/D/YYYY [@] h:mma') : null}
            </span>
          </div>
          <div className="col-sm-2">
            <div>
              <span className="hidden-sm hidden-md hidden-lg"><b>Due Date: </b></span>
              <span className="required-date">
                {moment(request.requiredBy).format('M/D/YYYY')}
              </span>
            </div>
            <div>
              <span className="hidden-sm hidden-md hidden-lg"><b>Due Time: </b></span>
              <span className="required-time">
                {moment(request.requiredBy).format('h:mma')}
              </span>
            </div>
          </div>
          <div className="col-sm-2">
            <div>
              <span className="hidden-sm hidden-md hidden-lg"><b>Category: </b></span>
              <span className="category">
                {request.productCategories}
              </span>
            </div>
            <div>
              <span className="hidden-sm hidden-md hidden-lg"><b>Material: </b></span>
              <span className="material">
                {request.productMaterials}
              </span>
            </div>
          </div>
          <div className="col-sm-1">
            <span className="hidden-sm hidden-md hidden-lg"><b>Status: </b></span>
            <span className="status">
              {this.renderStatus()}
            </span>
          </div>
          <div className="col-sm-1 text-right">
            <Link className="btn btn-default view-request-button" to={path}>View</Link>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ListRow);
