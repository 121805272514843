import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { withRouter } from 'react-router';
import { Route } from 'react-router-dom';
import List from './List';
import QuestionsForm from './Form';
import PropTypes from 'prop-types';

class QuestionsIndex extends Component {
  static propTypes = {
    questions: PropTypes.array
  };

  static defaultProps = {
    questions: []
  }

  render() {
    return <div className="questions">
      <div className="pull-right">
        { this.hideAddQuestionButton() ?
          null :
          <button
            className="btn btn-default"
            title={this.disableAddQuestionText()}
            disabled={this.disableAddQuestionButton()}
            onClick={this.openQuestionForm.bind(this)}>
            <FontAwesomeIcon icon={faPlusCircle} size="sm"/>&nbsp;
            Add Question
          </button>
        }
      </div>
      <h2> Questions </h2>
      <Route
        path={`${this.props.match.path}/questions/:questionid/edit`}
        component={props => <QuestionsForm
          {...this.props}
          onQuestionEvent={this.props.onQuestionUpdated}/>} />
      <Route
        path={`${this.props.match.path}/questions/new`}
        component={props => <QuestionsForm
          {...this.props}
          onQuestionEvent={this.props.onQuestionCreated}/>} />
      <div className="space-stack-xl"></div>

      <List
        {...this.props}
        questions={this.props.questions}
        noQuestionsText={this.noQuestionsText()} />
    </div>;
  }

  noQuestionsText() {
    if(this.props.products.length === 0){
      return "Add a product to the request before adding a question";
    }
  }

  disableAddQuestionButton() {
    if(this.disableAddQuestionText()){
      return true;
    }
    return false;
  }

  disableAddQuestionText() {
    if(this.props.products.length  === 0){
      return "Add a product to the request before adding a question";
    }
  }

  hideAddQuestionButton() {
    let matcher = /\/questions\/.+/i;
    let isInForm = matcher.test(this.props.history.location.pathname);
    return  this.props.disabled || isInForm;
  }

  openQuestionForm(){
    var requestId = this.props.match.params.id;
    this.props.history.push(`/requests/${requestId}/questions/new`);
  }
}

export default withRouter(QuestionsIndex);
