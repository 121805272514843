import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Form from './Form';

class ResponseFormHandler extends Component {
  constructor(props) {
    super(props);

    this.state = this.stateFromParams(props);
  }

  stateFromParams(props) {
    let questionId = props.match.params.questionid;
    let responseId = props.match.params.responseid;
    let parentResponseId;

    // change things up if url has /responses/*/responses/
    if(props.location.pathname.split('responses').length > 2) {
      responseId = props.match.params.responseresponseid;
      parentResponseId = props.match.params.responseid;
    }

    return {
      questionId,
      responseId,
      parentResponseId,
    };
  }

  componentDidUpdate(prevProps) {
    if(this.props.location.pathname !== prevProps.pathname) {
      this.setState(this.stateFromParams(this.props));
    }
  }

  render() {
    return <Form {...this.props}
      responseId={this.state.responseId}
      questionId={this.state.questionId}
      parentResponseId={this.state.parentResponseId}/>;
  }
}

export default withRouter(ResponseFormHandler);
