import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faPencilAlt, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { Link, Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Label,  Button } from '@dart-design/core';
import AttachmentList from 'components/attachments/AttachmentList';
import ResponseService from 'services/response';
import ResponsesForm from 'components/responses/FormHandler';
import If from 'components/IfCondition';
import Adal from 'services/adal';
import UserDisplayNames from 'components/UserDisplayNames';
import Display from 'utils/display';
import UserUtils from 'utils/user';
import DateUtils from 'utils/date';

class QuestionResponse extends Component {
  constructor(props){
    super(props);

    this.ref = React.createRef();
  }

  isDraft(){
    return this.props.response.submittedOn === '0001-01-01T00:00:00+00:00';
  }

  render() {
    const {
      response,
      match,
      parentResponse
    } = this.props;

    let currentUser = Adal.getCachedContext().getCachedUser().profile;
    let createdByCurrentUser = currentUser.oid === response.createdBy;

    var userIconStyle = {
      color: '#0056a2'
    };

    let requestId = match.params.id;
    let baseUrl = `/requests/${requestId}/questions/`;
    let editUrl = `${baseUrl}${response.questionId}/responses/${response.id}/edit`;
    if(response.parentResponseId){
      editUrl = `${baseUrl}${parentResponse.questionId}/responses/${response.parentResponseId}/responses/${response.id}/edit`;
    }

    this.hasBeenUpdated = moment(response.updatedOn)
      .isAfter(response.createdOn);

    let createdByUserName = UserUtils.displayNameFor(response.createdByUser);

    let html = [
      <div className="grows card bg-silver-light horizontal response-card"
        key='response-body'
        ref={this.ref}>
        <div className="row">
          <div className="col-md-12">

            <div className="response-actions pull-right">
              <If condition={this.isDraft()}>
                <Label text="Draft" context="warning" />
              </If>
              <If condition={createdByCurrentUser}>
                <Link to={editUrl}>
                  <FontAwesomeIcon icon={ faPencilAlt }></FontAwesomeIcon>
                </Link>
              </If>
            </div>

            <div>
              <b>{createdByUserName}</b>&nbsp;
            </div>
            <div>
              {this.getFormattedDate()}
            </div>
            <div>To: <UserDisplayNames userEmails={response.to}/></div>
            <div>Cc: <UserDisplayNames userEmails={response.cc}/></div>
            <div className="space-stack-lg" />
            <div className="rich-text-display" dangerouslySetInnerHTML={{__html: response.text}}></div>
            <AttachmentList
              attachments={response.attachments}
              getFile={this.getAttachmentFile.bind(this)}
            />
          </div>
        </div>
        {this.renderChildResponses()}
      </div>,
      <div className="space-inset-sm print-hide" key='spacer'></div>,
      <FontAwesomeIcon className="print-hide" key='user-icon' icon={faUserCircle} size="3x" style={userIconStyle}></FontAwesomeIcon>
    ];

    if (createdByCurrentUser) {
      html.reverse();
    }

    return (
      <div className="flexible horizontal space-stack-md">
        {html}
      </div>
    );
  }

  componentDidMount() {
    //scroll to response if it ends with "/repsonses/id" the same as its id
    var matcher = /responses\/(\d*)$/;
    var result = matcher.exec(this.props.location.pathname);
    if(result && parseInt(result[1]) === this.props.response.id) {
      Display.scrollToRef(this.ref);
    }
  }

  getFormattedDate() {
    if (this.hasBeenUpdated) {
      return `Edited on ${moment(this.props.response.updatedOn).format(DateUtils.atFormat)}`;
    } else {
      return `${moment(this.props.response.createdOn).format(DateUtils.atFormat)}`;
    }
  }

  getAttachmentFile(attachmentId) {
    return ResponseService.getAttachmentFile(this.props.response.id, attachmentId);
  }

  renderChildResponses() {
    if(!this.props.response.questionId) {
      return null;
    }

    let renderedResponses = null;
    if(this.props.response.childResponses) {
      renderedResponses = this.props.response.childResponses
        .map(r => <ResponseResponse {...this.props}
          response={r}
          parentResponse={this.props.response}
          key={r.id}/>);
    }

    return <div className="child-responses">
      <div className="row">
        <div className="col-md-12">
          <div className="pull-right">
            <If condition={this.props.response.statusId === 2 && this.props.requestStatus.id < 3}>
              <Button onClick={this.openResponseForm.bind(this)}>
                <FontAwesomeIcon icon={faPlusCircle}/>
                Add Response
              </Button>
            </If>
          </div>
        </div>
      </div>
      <hr className="print-hide"/>
      <Route
        path={`${this.props.match.path}/questions/:questionid/responses/:responseid/responses/new`}
        component={props => <ResponsesForm
          {...this.props}
          defaultNotifyUsers={this.props.response.cc}
          onResponseEvent={this.props.onResponseCreated}/>} />
      <Route
        path={`${this.props.match.path}/questions/:questionid/responses/:responseid/responses/:responseresponseid/edit`}
        component={props => <ResponsesForm
          {...this.props}
          onResponseEvent={this.props.onResponseUpdated}/>} />
      <If condition={this.props.response.childResponses.length > 0}>
        <div className="bg-white card">
          {renderedResponses}
        </div>
      </If>
    </div>;
  }

  openResponseForm() {
    let requestid = this.props.match.params.id;
    let questionid = this.props.response.questionId;
    let responseid = this.props.response.id;
    this.props.history.push(`/requests/${requestid}/questions/${questionid}/responses/${responseid}/responses/new`);
  }
}

const ResponseResponse = (props) => <QuestionResponse {...props}/>;

export default withRouter(QuestionResponse);
