import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ProductsTableCell from './ProductsTableCell';
import Highlight from 'utils/highlight';
import { parse as parseQuery } from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import Adal from 'services/adal';
import Display from 'utils/display';
import UserService from 'services/user';

class ProductsTableRow extends Component {
  static propTypes = {
    product: PropTypes.object
  }

  constructor(props){
    super(props);

    this.state = {
      currentUser: Adal.getCachedContext().getCachedUser().profile,
      allUsers: [],
    };

    this.rowRef = React.createRef();
  }

  render() {
    let product = this.props.product;
    let searchTerm = parseQuery(this.props.location.search).search;

    return <div className="card card-striped product-card" key={product.id}>
      <div className="row" ref={this.rowRef}>
        <ProductsTableCell className="col-sm-2" items={[
          { label: 'Stock', value: <Highlight search={searchTerm}>{product.stockCode}</Highlight> },
          { label: 'Item', value: <Highlight search={searchTerm}>{product.itemCode}</Highlight> },
          { label: 'Alternate for', value: product.alternateFor },
          { label: 'Replacement for', value: product.replacementFor }
        ]} />
        <ProductsTableCell className="col-sm-2" items={[
          { label: 'Print', value: product.printDescription },
          { label: 'Color', value: product.colors },
          { label: 'Emboss', value: product.embossedDescription }
        ]} />
        <ProductsTableCell className="col-sm-2" items={[
          { mobileLabel: 'Product Category', value: product.productCategory ? product.productCategory.name : null },
          { mobileLabel: 'Product Material(s)', value: product.toProductMaterials
            .map(ppm => ppm.productMaterial.name).join(', ') }
        ]} />
        <ProductsTableCell className="col-sm-2" items={[
          { mobileLabel: 'Producing Plant', value: product.producingPlants }
        ]} />
        <ProductsTableCell className="col-sm-1 col-md-2" items={[
          { mobileLabel: 'Annual Volume (M)', value: product.annualVolume }
        ]} />
        <ProductsTableCell className="col-sm-3 col-md-2 flexible horizontal" items={[
          { label: 'Case Count', value: product.caseCount },
          { label: 'Pkgs/Case', value: product.packagesPerCase },
          { label: 'Pkg Count', value: product.packageCount },
          { label: 'Stacks/Pkg', value: product.stacksPerPackage }
        ]}>
          <div className="space-inline-md grows"></div>
          <div>
            {this.renderEditButton.bind(this)(product)}
          </div>
        </ProductsTableCell>
      </div>
      { product.comments ?
      <>
        <hr className="print-hide" />
        <div className="row product-comments">
          <ProductsTableCell className="col-sm-1 hidden-sm product-comments-icon print-hide">
            <FontAwesomeIcon icon={faComment} size='lg'/>
          </ProductsTableCell>
          <ProductsTableCell className="col-sm-1 product-comments-text">
            {product.comments}
          </ProductsTableCell>
        </div>
      </>
      : null }
    </div>;
  }

  renderEditButton(product) {
    let requestId = this.props.match.params.id;
    let editUrl = `/requests/${requestId}/products/${product.id}/edit`;

    const createdByCurrentUser = this.state.currentUser.oid === product.createdBy;
    const createdByUser = this.state.allUsers.find(u => u.id === product.createdBy);
    let createdByAndIsMM = false;
    if(createdByUser){
      createdByAndIsMM = this.state.currentUser.roles.indexOf("MarketingManager") > -1 &&
        createdByUser.isMarketingManager;
    }

    const canEdit = createdByCurrentUser || createdByAndIsMM;

    if(this.props.disabled || !canEdit){
      return null;
    }

    return <Link to={editUrl}>
      <FontAwesomeIcon icon={faPencilAlt}></FontAwesomeIcon>
    </Link>;
  }

  componentDidMount() {
    let matcher = /products\/(\d+)/i;
    let results = matcher.exec(this.props.location.pathname);

    if(results){
      let productId = parseInt(results[1]);
      if(productId === this.props.product.id){
        Display.scrollToRef(this.rowRef);
      }
    }

    UserService.get().then(users => {
      this.setState({
        allUsers: users,
      });
    });
  }
}

export default withRouter(ProductsTableRow);
