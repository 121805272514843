import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faFileExcel, faFilePdf, faFileImage, faFileWord, faFilePowerpoint, faFileArchive, faEnvelope,  } from '@fortawesome/free-solid-svg-icons';
import MimeTypes from 'constants/MimeTypes';

class FileIcon extends Component {
  render() {
    let icon;

    switch(this.props.contentType) {
      case MimeTypes.xls:
      case MimeTypes.xlsx:
      case MimeTypes.csv:
        icon = faFileExcel;
        break;
      case MimeTypes.jpg:
      case MimeTypes.gif:
      case MimeTypes.png:
        icon = faFileImage;
        break;
      case MimeTypes.doc:
      case MimeTypes.docx:
        icon = faFileWord;
        break;
      case MimeTypes.pdf:
        icon = faFilePdf;
        break;
      case MimeTypes.ppt:
      case MimeTypes.pptx: {
        icon = faFilePowerpoint;
        break;
      }
      case MimeTypes.msg:
      case MimeTypes.eml:
        icon = faEnvelope;
        break;
      case MimeTypes.zip:
      case MimeTypes.sevenZip:
      case MimeTypes.xZip:
        icon = faFileArchive;
        break;
      default:
        icon = faFile;
    }

    return <FontAwesomeIcon icon={icon} style={this.props.style}></FontAwesomeIcon>;
  }
}

export default FileIcon;