import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import RequestsList from './List';
import RequestsForm from './Form';
import RequestsShow from './Show';

class RequestsIndex extends Component {
  render() {
    return (<Switch>
      <Route
        path='/requests/new'
        component={RequestsForm} />
      <Route
        path='/requests/:id/edit'
        component={RequestsForm} />
      <Route
        path='/requests/:id'
        component={RequestsShow} />
      <Route
        path='(/|/requests)'
        component={RequestsList} />
    </Switch>
    );
  }
}

export default RequestsIndex;
