import React, { Component } from 'react';
import Adal from 'services/adal';
import RequestService from 'services/request';
import { LoadingSpinner } from '@dart-design/core';
import { Dialog } from 'Dialog';

class EditRequestButton extends Component {
  constructor(props){
    super(props);

    this.state = {
      expanded: false,
      isAccepting: false,
      isClosing: false,
    };
  }

  shouldRender() {
    let currentUser = Adal.getCachedContext().getCachedUser().profile;

      return this.props.request.statusId === 2
          && (this.props.request.createdBy === currentUser.oid || currentUser.roles.includes("MarketingManager") || currentUser.roles.includes("Admin"));
  }

  render() {
    if (!this.shouldRender()) {
      return null;
    }

    let disabled = this.disableButtons();
    let title;

    if(this.props.request.peopleWithDrafts.length > 0){
      title = 'The following people have drafts on this request: ' +
        this.props.request.peopleWithDrafts.join(', ');
    }

    return <div className="pull-right">
      <button className="btn btn-default"
        onClick={this.markCustomerAccepted.bind(this)}
        disabled={disabled}
        title={title}>
        {this.state.isAccepting ?
          <LoadingSpinner /> : null}
        Mark Customer Accepted
      </button>

      <button className="btn btn-default"
        onClick={this.markClosed.bind(this)}
        disabled={disabled}
        title={title}>
        {this.state.isClosing ?
          <LoadingSpinner /> : null}
        Mark Closed
      </button>
    </div>;
  }

  markCustomerAccepted(){
    this.completeRequest(4);
  }

  markClosed(){
    this.completeRequest(5);
  }

  disableButtons() {
    return this.state.isClosing ||
      this.state.isAccepting ||
      this.props.request.peopleWithDrafts.length > 0;
  }

  completeRequest(statusId) {
    Dialog.confirm({
      message: 'Completing your request is irreversible. Continue?',
      submitText: 'Yes',
      cancelText: 'No',
      callback: () => {
        this.setState({
          isAccepting: statusId === 4,
          isClosing: statusId === 5,
        });
        RequestService.complete(this.props.request.id, statusId).then(() => {
          this.props.onRequestChanged();
          this.setState({
            isAccepting: false,
            isClosing: false,
          });
        });
      }
    });
  }
}

export default EditRequestButton;
