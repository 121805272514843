import Base from './serviceBase';
import FileSaver from 'file-saver';

class AttachmentService extends Base {
  displayName = 'Attachments';

  async create(requestId, file) {
    var formData = new FormData();

    formData.append('file', file);
    return await super.postFile(`/requests/${requestId}/attachments`, formData);
  }

  async deleteRequestAttachment(requestId, attachmentId) {
    return await super.delete(`/requests/${requestId}/attachments/${attachmentId}`);
  }

  async createQuestionAttachment(questionId, file) {
    var formData = new FormData();

    formData.append('file', file);
    return await super.postFile(`/questions/${questionId}/attachments`, formData);
  }

  async deleteQuestionAttachment(questionId, attachmentId) {
    return await super.delete(`/questions/${questionId}/attachments/${attachmentId}`);
  }

  async createResponseAttachment(responseId, file) {
    var formData = new FormData();

    formData.append('file', file);
    return await super.postFile(`/responses/${responseId}/attachments`, formData);
  }

  async deleteResponseAttachment(responseId, attachmentId) {
    return await super.delete(`/responses/${responseId}/attachments/${attachmentId}`);
  }

  openFile(contents, contentType, name) {
    var file = new Blob([contents], { type: contentType });
    FileSaver.saveAs(file, name);
  }
}

export default new AttachmentService();
