import React, { Component } from 'react';
import UserUtils from 'utils/user';
import If from 'components/IfCondition';
import { LoadingSpinner } from '@dart-design/core';

export default class UserDisplayNames extends Component {
  constructor(props) {
    super(props);
    this.displayNames = '';
    this.state = {
      displayNames: null,
      isLoading: false
    };
  }

  async componentDidMount() {
    await this.updateDisplayNames();
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.userEmails !== this.props.userEmails) {
      await this.updateDisplayNames();
    }
  }

  render() {
    return <span>
      <If condition={this.state.isLoading}>
        <LoadingSpinner/>
      </If>
      {this.state.displayNames}
    </span>;
  }

  updateDisplayNames() {
    this.setState({isLoading: true});

    if (!this.props.userEmails) {
      this.setState({isLoading: false});
      return;
    }

    return UserUtils.emailsToDisplayNames(this.props.userEmails.split(';'))
      .then(r => this.setState({
        displayNames: r.join(', '),
        isLoading: false
      }));
  }
}