import React, { Component } from 'react';

class UnAuthorized extends Component {

  render() {
    return <div className="container">
      <div className="page-header">
        <h1> You Are Not Authorized for this Application</h1>
      </div>

      <p>
          If you think this is in error, please
          <a href="mailto:Service.Desk@dart.biz">
          &nbsp;contact the helpdesk
          </a>.
      </p>
    </div>;
  }
}

export default UnAuthorized;
